import React, { Component } from "react";
import { Spin, Switch, Card } from "antd";
import {
    CheckOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../../core/i18n";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";
import { CarsListCustomCars } from "./CarsListCustomCars";
import { CarsListTopCars } from "./CarsListTopCars";

class CarsList extends Component {
    componentDidUpdate() {
        if (!CarCategoryStore.isLoading
            && CarCategoryStore.carCategories.length
            && !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length
            && !NewOrderStore.chooseCustomCar[NewOrderStore.getRouteWay()]) {
            NewOrderStore.selectCar();
        }
        if (!CarCategoryStore.isLoading && NewOrderStore.selectedCars) {
            NewOrderStore.filterExtras();
            NewOrderStore.setIfRouteHasToBePaidInAdvance();
        }
    }

    canShowCarList() {
        return (
            (
                NewOrderStore.mode === "excursion"
                    && NewOrderStore.excursionPoints
                    && NewOrderStore.excursionPoints.length > 1
                    && NewOrderStore.excursionPoints[0].place
                    && NewOrderStore.excursionPoints[0].place.length > 0
                    && NewOrderStore.excursionPoints[1].place
                    && NewOrderStore.excursionPoints[1].place.length > 0
            )
            || (
                NewOrderStore.start && NewOrderStore.start !== NewOrderStore.finish
                && (
                    NewOrderStore.finish || NewOrderStore.mode === "hour-rental"
                )
            )
        )
        && (
            (CarCategoryStore.routeDistance !== -1 && CarCategoryStore.routeDuration !== -1) || NewOrderStore.mode === "hour-rental"
        );
    }

    render() {
        const chooseCustomCar = NewOrderStore.chooseCustomCar[NewOrderStore.getRouteWay()];

        if (!this.canShowCarList()) {
            return <Spin size="large" spinning={CarCategoryStore.isLoading} />;
        }

        return (
            <Spin size="large" spinning={CarCategoryStore.isLoading}>
                <div style={{ display: "inline-block", marginRight: 6 }}>
                    {!chooseCustomCar ? (<h2 >{i18n.t("Dostupná vozidla")}</h2>) : (
                        <h2 >{i18n.t("Vybrat vozidla ručně")}</h2>)
                    }
                </div>
                <span className="white-space-nowrap">
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={chooseCustomCar}
                        onChange={() => {
                            NewOrderStore.resetSelectedCars();
                            NewOrderStore.resetSelectedCarsCustom();
                        }}
                    />
                    {!chooseCustomCar ? (<span style={{ fontSize: "13px" }}>{i18n.t("Vybrat vozidla ručně")}</span>) : (
                        <span style={{ fontSize: "13px" }}>{i18n.t("Zobrazit doporučená vozdila")}</span>)
                    }
                </span>
                <Card className="order-form__card custom-card">
                <div style={{ marginTop: 6 }} >
                    {chooseCustomCar && (
                        <CarsListCustomCars />
                    )}

                    {!chooseCustomCar && (
                        <CarsListTopCars
                            selectedCar={NewOrderStore.getSelectedCarId()}
                            onSelect={(car) => { NewOrderStore.selectCar(car); }}
                        />
                    )}
                </div>
                </Card>
            </Spin>
        );
    }
}

export default observer(CarsList);