import React, { Component } from "react";
import { Spin } from "antd";
import { observer } from "mobx-react";
import TourListStore from "../../../stores/Tours/TourListStore";
import TourContent from "./TourContent";
import Paginator from "../../Paginator";

class OrderTourPage extends Component {
    componentDidMount() {
        TourListStore.isLoading = true;
        TourListStore.load();
    }

    render() {
        return (
            <div className="activities-main content">
                <Spin spinning={TourListStore.isLoading}>
                    {TourListStore.tours
                        && (
                            <div>
                                <TourContent tours={TourListStore.tours} />
                                <Paginator store={TourListStore} />
                            </div>
                        )
                    }
                </Spin>
            </div>
        );
    }
}

export default observer(OrderTourPage);
