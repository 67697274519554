import CurrencyStore from "../../stores/Currency/CurrencyStore";
import getTotalPrice from "./getTotalPrice";
import NewOrderStore from "../../stores/Order/NewOrderStore";

export default function getTotalPriceInForeignCurrency(price, currency) {
    // Check if the currency is JPY
    const isJPY = currency === 'JPY';

    if (currency !== CurrencyStore.CZK && CurrencyStore.currencies) {
        let amount;
        if (price && NewOrderStore.mode !== "order") {
            // Apply rounding based on the currency
            if (isJPY) {
                amount = Math.round(price / CurrencyStore.currencies[currency].rate);
            } else {
                amount = (price / CurrencyStore.currencies[currency].rate).toFixed(2);
            }
            return `${amount} ${currency}`;
        }

        const { totalPrice } = getTotalPrice();

        const convertedPrice = totalPrice / CurrencyStore.currencies[currency].rate;

        // Apply rounding for JPY or keep two decimal points for other currencies
        const t = isJPY ? Math.round(convertedPrice) : convertedPrice.toFixed(2);

        amount = isJPY ? Math.round(parseFloat(t)) : parseFloat(t).toFixed(2);

        return `${amount} ${currency}`;
    }

    if (price && currency === CurrencyStore.CZK && CurrencyStore.currencies) {
        return `${price} ${currency}`;
    }
    return null;
}
