import { observable, makeObservable } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import i18n from "../../core/i18n";

class InvalidAddressModalStore {
    isLoading = false;

    isModalOpened = false;

    alreadySent = false;


    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isModalOpened: observable,
            alreadySent: observable
        });
    }


    open() {
        this.isModalOpened = true;
    }

    close() {
        this.isModalOpened = false;
        this.alreadySent = false;
        NotificationStore.reset();
    }


    sendEmail(data) {
        this.isLoading = true;
        RootStore.api.post("/invalid-address/send-email/", data).then(() => {
            this.alreadySent = true;
            this.isLoading = false;
            NotificationStore.processSuccess(i18n.t("Bude vás kontaktovat dispečer"), "invalidAddress");
        }).catch((e) => {
            this.isLoading = false;
            NotificationStore.processException(e, "invalidAddress");
        });
    }
}

export default new InvalidAddressModalStore();
