import React, { Component } from "react";
import {
    Popover, Switch, Card, Divider, Row, Col,
} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../../../../core/i18n";
import HelpButton from "../../../../HelpButton";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";

class ChildrenPart extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
        routeNumber: PropTypes.number.isRequired,
        formValues: PropTypes.objectOf(PropTypes.any).isRequired,
        formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    toggleChildrenCard = () => {
        const { chooseChild } = NewOrderStore;
        chooseChild[NewOrderStore.getRouteWay()] = !chooseChild[NewOrderStore.getRouteWay()];
    };


    render() {
        const {
            form, routeNumber, formValues, formItemLayout,
        } = this.props;
        const chooseChild = NewOrderStore.chooseChild[NewOrderStore.getRouteWay()];
        let isBabySeat=false; 
        if(NewOrderStore && NewOrderStore.car && NewOrderStore.car.isNoBabySeat){
            isBabySeat=true;
        }else{
            isBabySeat=false;
        }

        return (
            <div className="kids">
                <h2>
                    {i18n.t("Dítě")}
                    <Popover
                        overlayClassName="order-form__popover"
                        placement="top"
                        content={
                            i18n.t(
                                "Pokud s Vámi cestuje dítě, zvolte patřičnou dětskou autosedačku (podsedáky a dětské autosedačky)"
                            )}
                    >
                        <HelpButton />
                    </Popover>
                    <span className="white-space-nowrap">&nbsp;
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={chooseChild}
                        onChange={this.toggleChildrenCard} // eslint-disable-line no-param-reassign
                    />
                    </span>
                </h2>
                

                {chooseChild ? (

                    <Card className="order-form__card custom-card">
                        {isBabySeat?<p><span className="mr-10"><WarningOutlined /></span>
                            { i18n.t(
                                        "For selected vehicles, we do not provide child seats."
                                    )}
                        </p>:
                        <Row gutter={40}>
                            <Col xs={24} md={20}>

                                {form.render(
                                    `routes[${routeNumber}][kids1]`,
                                    {},
                                    formItemLayout
                                )}
                                {form.render(
                                    `routes[${routeNumber}][kids2]`,
                                    {},
                                    formItemLayout
                                )}
                                {form.render(
                                    `routes[${routeNumber}][kids3]`,
                                    {},
                                    formItemLayout
                                )}
                            </Col>
                        </Row>}
                    </Card>

                )
                    : <Divider />
                }
            </div>
        );
    }
}

export default observer(ChildrenPart);