import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Button } from "antd";
import { withRouter } from "next/router";
import { InfoCircleOutlined } from "@ant-design/icons";
import i18n from "../../core/i18n";
import PropTypes from "prop-types";
import NewOrderStore from "../../stores/Order/NewOrderStore";

class RouteErrorModal extends Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func.isRequired,
        }).isRequired,
    };

    handleContinue = () => {
        NewOrderStore.isModalOpened = false;
        NewOrderStore.checkisf1Address = false;
        NewOrderStore.checkValidation = true;

        if (NewOrderStore.isstartRed) {
            NewOrderStore.isstartRed = false;
        }

        if (NewOrderStore.isFinishRed) {
            NewOrderStore.isFinishRed = false;
        }

        this.props.form.onSubmit();
    };

    editAddress = () => {
        NewOrderStore.isModalOpened = false;
        window.scrollTo(0, 500);
    };

    render() {
        return (
            NewOrderStore.isModalOpened && (
                <Modal
                    width={550}
                    title={(
                        <>
                            <InfoCircleOutlined style={{ marginRight: "10px", color: "orange" }} />
                            <span dangerouslySetInnerHTML={{ __html: i18n.t("Neúplná adresa") }} />
                        </>
                    )}
                    open={true}
                    footer={null}
                    onCancel={() => { NewOrderStore.isModalOpened = false; }}
                >
                    <span>
                        {NewOrderStore.isNotice1
                            ? i18n.t("Hodinové pronájmy jsou dostupné pouze v rámci Prahy. Opravte svou adresu.")
                            : i18n.t("Jedna z vámi uvedených adres není dostatečně konkrétní. Abychom vám poskytli správné ceny a dostupnost, aktualizujte prosím níže uvedenou adresu výběrem konkrétní budovy nebo hotelu.")}
                    </span>
                    <div className="payall-modal-footer">
                        <Button
                            onClick={this.editAddress}
                            type="primary"
                            style={{ float: "left" }}
                        >
                            <span>{i18n.t("Upravit adresu")}</span>
                        </Button>
                        <Button
                            onClick={this.handleContinue}
                            type="default"
                            disabled={NewOrderStore.isNotice1}
                        >
                            <span>{i18n.t("pokračovat")}</span>
                        </Button>
                    </div>
                </Modal>
            )
        );
    }
}

export default withRouter(observer(RouteErrorModal));
