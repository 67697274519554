import React from "react";
import PropTypes from "prop-types";
import moment from 'moment-timezone';
import ListItemBlock from "../ListItemBlock";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import getTotalPrice from "../../../../../../utils/newOrderStore/getTotalPrice";


const PriceAndRecapDiscountWayThere = (props) => {
    const { hasSelectedCarsZeroPrice, showForeignCurrency } = props;
    const car = NewOrderStore.selectedCars.thereRoute[0];
    const { priceCarsStart, discountedFromPrice, surchargesFromPrice } = getTotalPrice();

    const isDiscount = () => {
        const dates = NewOrderStore.getFormattedPickupDates();
        const pickupDate = moment(dates.pickupDateTime);
        const discountMinDate = moment(car?.thereDiscountMinDate ?? '1970-01-01');
        const discountMaxDate = moment(car?.thereDiscountMaxDate ?? '1970-01-01');
    
        return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
    };
    
    const isSurcharge = () => {
        const dates = NewOrderStore.getFormattedPickupDates();
        const pickupDate = moment(dates.pickupDateTime);
        const surchargesMinDate = moment(car?.thereSurchargesMinDate ?? '1970-01-01');
        const surchargesMaxDate = moment(car?.thereSurchargesMaxDate ?? '1970-01-01');
    
        return pickupDate.isBetween(surchargesMinDate, surchargesMaxDate, null, '[]');
    };
    

    return (
        <React.Fragment>
            {!isDiscount() && !isSurcharge() && (
                <ListItemBlock
                    labelText="Cena tam"
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsStart={priceCarsStart}
                    CurrencyStore={CurrencyStore}
                    showForeignCurrency={showForeignCurrency}
                />
            )}

            {car && isDiscount() && (
                <React.Fragment>
                    <ListItemBlock
                        labelText="Cena tam"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={priceCarsStart}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}
                        BlockType="delete"
                    />
                    <ListItemBlock
                        labelText="Snížená cena"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={discountedFromPrice}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}

                    />
                </React.Fragment>
            )}

            {car && isSurcharge() && (
                <React.Fragment>
                    {!isDiscount() && (
                        <ListItemBlock
                            labelText="Cena tam"
                            hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                            priceCarsStart={priceCarsStart}
                            CurrencyStore={CurrencyStore}
                            showForeignCurrency={showForeignCurrency}
                        />
                    )}

                    <ListItemBlock
                        labelText="příplatky"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={surchargesFromPrice}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}
                    />

                    {isDiscount() && (
                        <ListItemBlock
                            labelText="Mezisoučet ceny"
                            hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                            priceCarsStart={surchargesFromPrice + discountedFromPrice}
                            CurrencyStore={CurrencyStore}
                            showForeignCurrency={showForeignCurrency}
                        />
                    )}

                    {!isDiscount() && (
                        <ListItemBlock
                            labelText="Mezisoučet ceny"
                            hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                            priceCarsStart={surchargesFromPrice + priceCarsStart}
                            CurrencyStore={CurrencyStore}
                            showForeignCurrency={showForeignCurrency}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

PriceAndRecapDiscountWayThere.propTypes = {
    hasSelectedCarsZeroPrice: PropTypes.bool.isRequired,
    showForeignCurrency: PropTypes.any.isRequired,
};

export default PriceAndRecapDiscountWayThere;
