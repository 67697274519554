import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Button } from "antd";
import { withRouter } from "next/router";
import {
    InfoCircleOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import i18n from "../../core/i18n";
import NewOrderStore from "../../stores/Order/NewOrderStore";

class RouteDuplicateErrorModal extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
    }

    render() {
        const { form } = this.props;

        const handleContinue = () => {
            NewOrderStore.setIsDuplicateModalOpened(false);
            NewOrderStore.setCheckDuplicateError(false);
            form.onSubmit();
        };

        const showDuplicate = () => {
            NewOrderStore.setIsDuplicateModalOpened(false);
            const params = { lang: this.props.router.query.lang };
            window.location.replace(`/${params.lang}/your-orders/`);
            window.scrollTo(0, 180);
        };

        const { duplicateRouteIds } = NewOrderStore;

        return (
            NewOrderStore.isDuplicateModalOpened && (
                <Modal
                    width={650}
                    title={(
                        <>
                            <InfoCircleOutlined style={{ marginRight: "10px", color: "orange" }} />
                            {/* eslint-disable-next-line react/no-danger */}
                            <span dangerouslySetInnerHTML={{ __html: i18n.t("Duplicitní cesta") }} />
                        </>
                    )}
                    open
                    footer={false}
                    onCancel={() => {
                        NewOrderStore.setIsDuplicateModalOpened(false);
                        NewOrderStore.clearDuplicateRouteIds();
                    }}
                >
                    {duplicateRouteIds.length === 1 && (
                        <p>
                            {i18n.t("Již máte vytvořenou stejnou cestu č. {{duplicateRouteIds}}, přejete si ji zobrazit nebo vytvořit novou rezervaci?", { duplicateRouteIds })}
                        </p>
                    )}
                    {duplicateRouteIds.length > 1 && (
                        <p>
                            {i18n.t("Již máte vytvořené stejné cesty č. {{duplicateRouteIds}}, přejete si je zobrazit nebo vytvořit novou rezervaci?", { duplicateRouteIds })}
                        </p>
                    )}
                    <div className="payall-modal-footer">
                        <Button
                            onClick={showDuplicate}
                            type="primary"
                            style={{ float: "left" }}
                        >
                            {duplicateRouteIds.length > 1 ? <span>{i18n.t("Zobrazit duplicitní cesty")}</span> : <span>{i18n.t("Zobrazit duplicitní cestu")}</span> }
                        </Button>
                        <Button
                            onClick={handleContinue}
                            type="primary"
                        >
                            <span>{i18n.t("Pokračovat a vytvořit novou rezervaci")}</span>
                        </Button>
                    </div>
                </Modal>
            )
        );
    }
}

export default withRouter(observer(RouteDuplicateErrorModal));