import { List } from "antd";
import i18n from "../../../../../core/i18n";
import getAmountInForeignCurrency from "../../../../../utils/newOrderStore/getAmountInForeignCurrency";

export default function ListItemBlock(props) {
    const ListItem = List.Item;
    const {
        labelText, hasSelectedCarsZeroPrice, priceCarsStart, CurrencyStore, showForeignCurrency
    } = props;
    const BlockType = !!((props.BlockType && props.BlockType == "delete"));

    return (
        <ListItem>
            <div className="order-form__total">
                <p className="order-form__total-text">
                    {i18n.t(labelText)}
                </p>
                <p className="order-form__total-price">
                    {BlockType ? (
                        <del style={{ fontSize: "unset" }}>
                            {hasSelectedCarsZeroPrice && (
                                <span>
                                    {i18n.t("Bude určena později")}
                                </span>
                            )}
                            {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                                <span>
                                    {priceCarsStart}
                            &nbsp;
                                    {CurrencyStore.CZK}
                                </span>
                            )}
                            {!!(priceCarsStart && showForeignCurrency) && (
                                <span>
                                    {getAmountInForeignCurrency(priceCarsStart, CurrencyStore.selectedCurrency)}
                                </span>
                            )}
                        </del>
                    ) : (
<>
                    {hasSelectedCarsZeroPrice && (
                        <span>
                            {i18n.t("Bude určena později")}
                        </span>
                    )}
                    {!hasSelectedCarsZeroPrice && !showForeignCurrency && (
                        <span>
                            {priceCarsStart}
                            &nbsp;
                            {CurrencyStore.CZK}
                        </span>
                    )}
                    {!!(priceCarsStart && showForeignCurrency) && (
                        <span>
                            {getAmountInForeignCurrency(priceCarsStart, CurrencyStore.selectedCurrency)}
                        </span>
                    )}
</>
                    )}

                </p>
            </div>
        </ListItem>
    );
}
