import React from "react";
import {
    Button, Card, Col, Row, Empty,
} from "antd";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../../core/i18n";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";
import getAmountInForeignCurrency from "../../../../../../utils/newOrderStore/getAmountInForeignCurrency";
import CustomCarSelectList from "../../CustomCarSelectList";
import getTotalPrice from "../../../../../../utils/newOrderStore/getTotalPrice";

export const CarsListCustomCars = observer(() => {
    const { totalPrice } = getTotalPrice();

    return (
        <React.Fragment>
            <Card
                style={{
                    borderColor: NewOrderStore.error
                        && !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length
                        && !NewOrderStore.isLoading ? "#f5222d" : null,
                }}
                title={(
                    <div className="custom-car__choose-title">
                        <div className="custom-car__choose-title-text">{i18n.t("Vyberte vozidla")}</div>
                        <div className="custom-car__choose-title-price">
                            <span className="custom-car__choose-title-price-label">
                                {i18n.t("Cena vozidel celkem")}
                                {":"}
                            </span>
                            {" "}
                            <strong>
                                {!!(CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies
                                        && getAmountInForeignCurrency(totalPrice, CurrencyStore.selectedCurrency)) && (
                                        <>
                                            <span>
                                                {getAmountInForeignCurrency(totalPrice, CurrencyStore.selectedCurrency)}
                                            </span>
                                            <span style={{ color: "#808080", fontSize: "12px" }}>
                                                {" / "}
                                                {totalPrice}
                                                {" "}
                                                {CurrencyStore.CZK}
                                            </span>
                                        </>
                                )}
                                {(CurrencyStore.selectedCurrency === CurrencyStore.CZK) && (
                                    <span>
                                        {totalPrice}
                                        {" "}
                                        {CurrencyStore.CZK}
                                    </span>
                                )}
                            </strong>
                        </div>
                    </div>
                )}
            >
                <div className="custom-car__choose-list">
                    <CustomCarSelectList />
                </div>
                {!NewOrderStore.error && !CarCategoryStore.customCarCategories.length && (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={i18n.t("Pro zobrazení dostupných vozidel nejdříve vyberte místo z-do, datum a čas.")}
                    />
                )}
            </Card>
            {(NewOrderStore.error
                && !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length
                && !NewOrderStore.isLoading) && (
                <span style={{ color: "#f5222d" }}>
                    {i18n.t("Vyberte vozidlo")}
                </span>
            )}
            {CarCategoryStore.customCarCategories.length > 3 && (
                <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                    <Col>
                        {NewOrderStore.showCustomCarsCount < CarCategoryStore.customCarCategories.length && (
                            <Button
                                style={{ margin: 5 }}
                                type="primary"
                                onClick={() => {
                                    NewOrderStore.showCustomCarsCount += 3;
                                }}
                            >
                                {i18n.t("Zobrazit více")}
                            </Button>
                        )}
                        {NewOrderStore.showCustomCarsCount > 3 && (
                            <Button
                                style={{ margin: 5 }}
                                onClick={() => {
                                    NewOrderStore.showCustomCarsCount = 3;
                                }}
                            >
                                {i18n.t("Skrýt")}
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
});

CarsListCustomCars.propTypes = {
};

CarsListCustomCars.defaultProps = {
};
