import React from "react";
import PropTypes from "prop-types";
import ListItemBlock from "../ListItemBlock";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";

const PriceAndRecapWayBackExtraDiscount = (props) => {
    const {
        isDiscount, hasSelectedCarsZeroPrice, priceCarsEnd, showForeignCurrency, surchargesToPrice, discountedToPrice,
    } = props;

    return (
        <React.Fragment>
            {!isDiscount() && (
                <ListItemBlock
                    labelText="Cena zpět"
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsStart={priceCarsEnd}
                    CurrencyStore={CurrencyStore}
                    showForeignCurrency={showForeignCurrency}
                    BlockType="delete"
                />
            )}

            <ListItemBlock
                labelText="Snížená cena"
                hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                priceCarsStart={discountedToPrice}
                CurrencyStore={CurrencyStore}
                showForeignCurrency={showForeignCurrency}
            />

            <ListItemBlock
                labelText="příplatky"
                hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                priceCarsStart={surchargesToPrice}
                CurrencyStore={CurrencyStore}
                showForeignCurrency={showForeignCurrency}
            />

            {isDiscount() && (
                <ListItemBlock
                    labelText="Mezisoučet ceny"
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsStart={surchargesToPrice + discountedToPrice}
                    CurrencyStore={CurrencyStore}
                    showForeignCurrency={showForeignCurrency}
                />
            )}

            {!isDiscount() && (
                <ListItemBlock
                    labelText="Mezisoučet ceny"
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsStart={surchargesToPrice + discountedToPrice}
                    CurrencyStore={CurrencyStore}
                    showForeignCurrency={showForeignCurrency}
                />
            )}
        </React.Fragment>
    );
};

PriceAndRecapWayBackExtraDiscount.propTypes = {
    hasSelectedCarsZeroPrice: PropTypes.bool.isRequired,
    isDiscount: PropTypes.bool.isRequired,
    priceCarsEnd: PropTypes.number.isRequired,
    showForeignCurrency: PropTypes.any.isRequired,
    surchargesToPrice: PropTypes.number.isRequired, 
    discountedToPrice: PropTypes.number.isRequired,
};

export default PriceAndRecapWayBackExtraDiscount;
