import React from "react";
import PropTypes from "prop-types";
import ListItemBlock from "../ListItemBlock";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import getTotalPrice from "../../../../../../utils/newOrderStore/getTotalPrice";
import PriceAndRecapWayBackNoExtraDiscount from "./PriceAndRecapWayBackNoExtraDiscount";
import PriceAndRecapWayBackExtraDiscount from "./PriceAndRecapWayBackExtraDiscount";
import moment from 'moment-timezone';

const PriceAndRecapDiscountWayBack = (props) => {
    const { hasSelectedCarsZeroPrice, showForeignCurrency } = props;
    const car = NewOrderStore.selectedCars.backRoute[0];
    const {
        priceCarsEnd, discountedToPrice, surchargesToPrice, extraDiscountedPrice,
    } = getTotalPrice();

    const isDiscount = () => {
        const dates = NewOrderStore.getFormattedPickupDates();
        const returnPickupDate = moment(dates.returnPickupDateTime);
        const discountMinDate = moment(car.backDiscountMinDate);
        const discountMaxDate = moment(car.backDiscountMaxDate);

        return returnPickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
    };

    const isSurcharge = () => {
        const dates = NewOrderStore.getFormattedPickupDates();
        const returnPickupDate = moment(dates.returnPickupDateTime);
        const surchargesMinDate = moment(car.backSurchargesMinDate);
        const surchargesMaxDate = moment(car.backSurchargesMaxDate);

        return returnPickupDate.isBetween(surchargesMinDate, surchargesMaxDate, null, '[]');
    };

    const isExtraDiscount = () => {
        return !!car.isreturnDiscount;
    };

    return (
        <React.Fragment>
            {car && !isDiscount() && !isSurcharge() && !isExtraDiscount() && (
                <ListItemBlock
                    labelText="Cena zpět"
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsStart={priceCarsEnd}
                    CurrencyStore={CurrencyStore}
                    showForeignCurrency={showForeignCurrency}
                />
            )}

            {car && isExtraDiscount() && extraDiscountedPrice > 0 && (
                <React.Fragment>
                    <ListItemBlock
                        labelText="Cena zpět"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={priceCarsEnd}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}
                        BlockType="delete"
                    />
                    <ListItemBlock
                        labelText="Snížená cena"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={extraDiscountedPrice}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}
                    />
                </React.Fragment>
            )}

            {car && isDiscount() && (
                <React.Fragment>
                    <ListItemBlock
                        labelText="Cena zpět"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={priceCarsEnd}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}
                        BlockType="delete"
                    />
                    <ListItemBlock
                        labelText="Snížená cena"
                        hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                        priceCarsStart={discountedToPrice}
                        CurrencyStore={CurrencyStore}
                        showForeignCurrency={showForeignCurrency}
                    />
                </React.Fragment>
            )}

            {car && isSurcharge() && !isExtraDiscount() && (
                <PriceAndRecapWayBackNoExtraDiscount
                    isDiscount={isDiscount}
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsEnd={priceCarsEnd}
                    showForeignCurrency={showForeignCurrency}
                    surchargesToPrice={surchargesToPrice}
                    discountedToPrice={discountedToPrice}
                />
            )}

            {car && isSurcharge() && isExtraDiscount() && (
                <PriceAndRecapWayBackExtraDiscount
                    isDiscount={isDiscount}
                    hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                    priceCarsEnd={priceCarsEnd}
                    showForeignCurrency={showForeignCurrency}
                    surchargesToPrice={surchargesToPrice}
                    discountedToPrice={discountedToPrice}
                />
            )}
        </React.Fragment>
    );
};

PriceAndRecapDiscountWayBack.propTypes = {
    hasSelectedCarsZeroPrice: PropTypes.bool.isRequired,
    showForeignCurrency: PropTypes.any.isRequired,
};

export default PriceAndRecapDiscountWayBack;
