import React, { Component } from "react";
import { Card, Popover, Col, Row, Switch, Checkbox, Divider } from "antd";
import {
    CloseOutlined,
    CheckOutlined, ClockCircleOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import HelpButton from "../../../../HelpButton";
import i18n from "../../../../../core/i18n";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import ZoneStore from "../../../../../stores/Zone/ZoneStore";
import CarCategoryStore from "../../../../../stores/CarCategory/CarCategoryStore";
import MeansOfTransport from "./MeansOfTransport";
import dayjs from "dayjs";

class PickUpDate extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
        routeNumber: PropTypes.number.isRequired,
        formValues: PropTypes.objectOf(PropTypes.any).isRequired,
        formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    toggleBackRoute = () => {
        NewOrderStore.backRoute = !NewOrderStore.backRoute;
        NewOrderStore.backRouteStep = false;

        if (!NewOrderStore.backRoute) {
            NewOrderStore.selectedExtras.backRoute = [];
            NewOrderStore.returnPickupTime = null;
            NewOrderStore.returnPickupDate = null;
            NewOrderStore.returnRouteMode = false;
        } else {
            const pickupDate = NewOrderStore.pickupDate;
    
            if (pickupDate) {
                const formattedPickupDate = dayjs(pickupDate).format('YYYY-MM-DD');
                NewOrderStore.returnPickupDate = dayjs(formattedPickupDate).add(3, 'day').format('YYYY-MM-DD');
            }
    
            // Duplicate selected cars from thereRoute to backRoute if it’s a custom car selection
            if (NewOrderStore.selectedCars.thereRoute.length > 0) {
                NewOrderStore.resetSelectedCarsCustom();
            }
        }

        NewOrderStore.loadCarCategoriesAndSetPaymentType();
        NewOrderStore.setIfRouteHasToBePaidInAdvance();

        // Log the final formValues after toggle
        //console.log("Final formValues:", JSON.stringify(NewOrderStore.formValues, null, 2));
    };

    toggleBackRouteStep = () => {
        NewOrderStore.backRouteStep = !NewOrderStore.backRouteStep;
    };

    pickupDateLabel = () => {
        if (NewOrderStore.returnRouteMode) {
            return i18n.t("Termín pro zpáteční cestu");
        }
        return i18n.t("Termín vyzvednutí");
    };

    getRouteNumber = () => NewOrderStore.getRouteNumber();

    pickupTimeLabel = (zoneInfo) => {
        const zoneInfoFinal = NewOrderStore.returnRouteMode ? CarCategoryStore.returnZoneInfo : zoneInfo;
        return (
            <span style={{ fontWeight: 500 }}>
                <ClockCircleOutlined />
                &nbsp;
                {NewOrderStore.usePickupDateLive
                && parseInt(zoneInfoFinal, 10) === ZoneStore.TYPE_AIRPORT_FROM ? i18n.t("Čas příletu") : i18n.t("Čas")}
                &nbsp;
            </span>
        );
    };

    getAMPM = (dateTime) => {
        if (dateTime) {
            return dayjs(dateTime).format('A');
        }
    }

    returnTimeLabel = (zoneInfo) => {
        const zoneInfoFinal = NewOrderStore.returnRouteMode ? CarCategoryStore.returnZoneInfo : zoneInfo;
        return (
            <span style={{ fontWeight: 500 }}>
                <ClockCircleOutlined />
                &nbsp;
                {NewOrderStore.usePickupDateLive
                && parseInt(zoneInfoFinal, 10) === ZoneStore.TYPE_AIRPORT_FROM ? i18n.t("Čas příletu") : i18n.t("Čas odjezdu")}
                &nbsp;
                {parseInt(zoneInfoFinal, 10) === ZoneStore.TYPE_AIRPORT_TO && (
                    <Popover
                        overlayClassName="order-form__popover"
                        placement="top"
                        content={i18n.t("Vyplňte prosím čas vyzvednutí, nikoli čas odletu vašeho letu.")}
                    >
                        <HelpButton />
                    </Popover>
                )}
            </span>
        );
    };

    render() {
        const { form, formValues, formItemLayout } = this.props;
        const { mode, backRouteStep } = NewOrderStore;

        let { routeNumber } = this.props;
        if (NewOrderStore.returnRouteMode) {
            routeNumber = 1;
        }

        const { zoneInfo } = CarCategoryStore;
        const dateFormat = 'YYYY-MM-DD';
        const nrm = formValues.routes[this.getRouteNumber()] && formValues.routes[this.getRouteNumber()].pickupDate
    ? dayjs(formValues.routes[this.getRouteNumber()].pickupDate)
    : NewOrderStore.getDefaultPickupDate();
    
        // Log the form object
        // console.log("Form Object:", form);

        // Ensure valid dayjs objects
        const pickupDateOnly = nrm && nrm.isValid() ? nrm.format("YYYY-MM-DD") : null;
        const pickupTimeOnly = nrm && nrm.isValid() ? nrm.format("HH:mm") : null;
        const returnDateOnly = NewOrderStore.returnPickupDate;
        const returnTimeOnly = NewOrderStore.returnPickupTime;

        return (
            <div className="pick-up-date">
                <h2>
                    {this.pickupDateLabel()}
                    {zoneInfo === ZoneStore.TYPE_AIRPORT_TO && (NewOrderStore.mode !== "excursion" && NewOrderStore.mode !== "hour-rental") && (
                        <Popover
                            overlayClassName="order-form__popover"
                            placement="top"
                            content={i18n.t("V případě transferu na letiště doporučujeme 2 hod a 30 min před odletem")}
                        >
                            <HelpButton />
                        </Popover>
                    )}
                </h2>
                <Card className="order-form__card custom-card">
                    <Row gutter={40}>
                        <Col xs={24} md={17}>
                            {form.render(
                                `routes[${routeNumber}][pickupDate]`,
                                { initialValue: pickupDateOnly ? dayjs(pickupDateOnly, dateFormat) : null },
                                {
                                    labelCol: formItemLayout.labelCol,
                                    wrapperCol: formItemLayout.wrapperCol,
                                    validateStatus: (() => {
                                        const status = NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber}][pickupDate]`) ? "error" : null;
                                        return status;
                                    })(),
                                    help: NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber}][pickupDate]`) ? i18n.t("Prosím vyberte datum") : "",
                                }
                            )}
                            <div className="am-pm">
                                {form.render(
                                    `routes[${routeNumber}][pickupTime]`,
                                    { initialValue: pickupTimeOnly ? dayjs(pickupTimeOnly, 'HH:mm') : null },
                                    {
                                        label: this.pickupTimeLabel(CarCategoryStore.zoneInfo),
                                        labelCol: formItemLayout.labelCol,
                                        wrapperCol: formItemLayout.wrapperCol,
                                        validateStatus: NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber}][pickupTime]`) ? "error" : null,
                                        help: NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber}][pickupTime]`) ? i18n.t("Vyberte čas") : "",
                                    }
                                )}
                                <span className="am-pm-value"> {this.getAMPM(form.form.getFieldValue(`routes[${routeNumber}][pickupTime]`))}</span>
                            </div>
                            <MeansOfTransport
                                returnRouteMode={NewOrderStore.returnRouteMode}
                                formValues={formValues}
                                routeNumber={routeNumber}
                                formItemLayout={formItemLayout}
                                form={form}
                            />
                        </Col>
                        {zoneInfo === ZoneStore.TYPE_AIRPORT_FROM && (
                            <Col xs={24} md={17}>
                                <div className="order-form__checkbox">
                                    {form.render(
                                        `routes[${routeNumber}][withoutLuggage]`,
                                        { initialValue: NewOrderStore.defaultValues.withoutLuggage },
                                        formItemLayout
                                    )}
                                </div>
                                <div className="order-form__checkbox">
                                    {form.render(
                                        `routes[${routeNumber}][withLuggage]`,
                                        { initialValue: NewOrderStore.defaultValues.withLuggage },
                                        formItemLayout
                                    )}
                                </div>
                                {NewOrderStore.defaultValues.withLuggage && (
                                    <div className="order-form__checkbox">
                                        {form.render(
                                            `routes[${routeNumber}][excessiveLuggage]`,
                                            { initialValue: NewOrderStore.defaultValues.excessiveLuggage },
                                            formItemLayout
                                        )}
                                    </div>
                                )}
                            </Col>
                        )}
                    </Row>
                    {mode !== "excursion" && mode !== "hour-rental" && !NewOrderStore.returnRouteMode && !NewOrderStore.editId && (
                        <div className="order-form__card order-form__back-route">
                           <Divider />
                            <div style={{ marginTop: 10, marginBottom: 15 }}>
                                    <span className="mr-12">
                                        {i18n.t("Termín zpáteční cesty")}
                                        <Popover
                                            overlayClassName="order-form__popover"
                                            placement="top"
                                            content={
                                                i18n.t("Vyberte si jednu cestu nebo zpáteční dopravu. Chcete-li rezervovat zpáteční transfer pro jinou trasu, vytvořte novou rezervaci.")
                                            }
                                        >
                                            <HelpButton />
                                        </Popover>
                                    </span>
                                    <span className="white-space-nowrap">
                                        <Switch
                                            checked={NewOrderStore.backRoute}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            onChange={this.toggleBackRoute}
                                        />
                                        {NewOrderStore.backRoute ? (
                                            <span style={{ fontWeight: 500, fontSize: "18px", marginLeft: 10 }}>{i18n.t("Ano")}</span>
                                        ) : (
                                            <span style={{ fontWeight: 500, fontSize: "18px", marginLeft: 10 }}>{i18n.t("Ne")}</span>
                                        )}
                                    </span>
                            </div>
                            {!NewOrderStore.backRoute && form.resetFields("routes[1][pickupTime]")}
                            {NewOrderStore.backRoute ? (
                                <>
                                    <Row gutter={40}>
                                        <Col xs={24} md={17}>
                                            <div className="order-form__back-route-details">
                                                {form.render(
                                                    `routes[${routeNumber + 1}][pickupDate]`,
                                                    { initialValue: returnDateOnly ? dayjs(returnDateOnly, dateFormat) : null },
                                                    {
                                                        labelCol: formItemLayout.labelCol,
                                                        wrapperCol: formItemLayout.wrapperCol,
                                                        validateStatus: (() => {
                                                            const status = NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber + 1}][pickupDate]`) ? "error" : null;
                                                            return status;
                                                        })(),
                                                        help: NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber + 1}][pickupDate]`) ? i18n.t("Prosím vyberte datum") : "",
                                                    }
                                                )}
                                                <div className="am-pm">
                                                    {form.render(
                                                        `routes[${routeNumber + 1}][pickupTime]`,
                                                        { initialValue: returnTimeOnly ? dayjs(returnTimeOnly, 'HH:mm') : null },
                                                        {
                                                            label: this.returnTimeLabel(CarCategoryStore.returnZoneInfo),
                                                            labelCol: formItemLayout.labelCol,
                                                            wrapperCol: formItemLayout.wrapperCol,
                                                            validateStatus: NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber + 1}][pickupTime]`) ? "error" : null,
                                                            help: NewOrderStore.error && !form.form.getFieldValue(`routes[${routeNumber + 1}][pickupTime]`) ? i18n.t("Vyberte čas") : "",
                                                        }
                                                    )}
                                                    <span className="am-pm-value"> {this.getAMPM(form.form.getFieldValue(`routes[${routeNumber + 1}][pickupTime]`))}</span>
                                                </div>
                                                <MeansOfTransport
                                                    returnRouteMode
                                                    formValues={formValues}
                                                    routeNumber={routeNumber + 1}
                                                    formItemLayout={formItemLayout}
                                                    form={form}
                                                />
                                            </div>
                                        </Col>
                                        {zoneInfo === ZoneStore.TYPE_AIRPORT_TO && (
                                            <Col xs={24} md={17}>
                                                <div className="order-form__checkbox">
                                                    {form.render(
                                                        `routes[${routeNumber + 1}][withoutLuggage]`,
                                                        { initialValue: NewOrderStore.defaultValues.withoutLuggage },
                                                        formItemLayout
                                                    )}
                                                </div>
                                                <div className="order-form__checkbox">
                                                    {form.render(
                                                        `routes[${routeNumber + 1}][withLuggage]`,
                                                        { initialValue: NewOrderStore.defaultValues.withLuggage },
                                                        formItemLayout
                                                    )}
                                                </div>
                                                {NewOrderStore.defaultValues.withLuggage && (
                                                    <div className="order-form__checkbox">
                                                        {form.render(
                                                            `routes[${routeNumber + 1}][excessiveLuggage]`,
                                                            { initialValue: NewOrderStore.defaultValues.excessiveLuggage },
                                                            formItemLayout
                                                        )}
                                                    </div>
                                                )}
                                            </Col>
                                        )}
                                        <div className="order-form__checkbox-return-modify">
                                            <Checkbox
                                                onChange={this.toggleBackRouteStep}
                                                checked={backRouteStep}
                                                id="checkbox-edit-car"
                                            >
                                                {i18n.t("Jine vozidlo nebo adresa pro cestu zpet")}
                                            </Checkbox>
                                            <span className="mr-10">
                                                <Popover
                                                    overlayClassName="order-form__popover"
                                                    placement="top"
                                                    content={i18n.t("Upravit vozidlo zpáteční cesty v dalším kroce")}
                                                >
                                                    <HelpButton />
                                                </Popover>
                                            </span>
                                        </div>
                                    </Row>
                                </>
                            ) : (
                                <span />
                            )}
                        </div>
                    )}
                </Card>
            </div>
        );
    }
}

export default observer(PickUpDate);
