// Notes.js
import React from "react";
import { Popover, Card, Switch, Divider } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import HelpButton from "../../../../HelpButton";
import i18n from "../../../../../core/i18n";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";

const Notes = ({ form, routeNumber, formValues }) => {
    const showNotes = NewOrderStore.showNotes;

    const toggleNotesCard = () => {
        NewOrderStore.toggleNotesCard();
    };

    return (
        <div className="order-form-notes FlipDiv">
            <h2 className="order-form-notes-title">
                {i18n.t("Poznámka (speciální požadavky)")}
                <Popover
                    overlayClassName="order-form__popover"
                    placement="top"
                    content={i18n.t("Poznámky k této rezervaci")}
                >
                    <HelpButton />
                </Popover>
                <span className="white-space-nowrap">&nbsp;
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={showNotes}
                        onChange={toggleNotesCard}
                    />
                </span>
            </h2>

            {showNotes ? (
                <Card className="order-form__card custom-card">
                    <div className="order-form__notes-content">
                        {form.render(
                            `routes[${routeNumber}][noteForDriver]`,
                            {
                                initialValue: formValues.routes[routeNumber].noteForDriver || "",
                                trigger: "onBlur"
                            }
                        )}
                    </div>
                </Card>
            ) : (
                <Divider />
            )}
        </div>
    );
};

Notes.propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    routeNumber: PropTypes.number.isRequired,
    formValues: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default observer(Notes);
