import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Button, Popover, Card, Tag, Divider } from "antd";
import { MinusOutlined, PlusOutlined, UserOutlined, ShoppingOutlined } from "@ant-design/icons";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import GetCarImg from "../../../../order/GetCarImg";
import CurrencyStore from "../../../../../stores/Currency/CurrencyStore";
import getAmountInForeignCurrency from "../../../../../utils/newOrderStore/getAmountInForeignCurrency";
import i18n from "../../../../../core/i18n";
import CarCategoryDescriptionToolTip from "../../../../orderList/CarCategoryDescriptionTooltip";
import getLuggageCapacityContent from "../../../../order/getLuggageCapacityContent";
import moment from 'moment-timezone';

class CustomCarSelect extends Component {
    static propTypes = {
        car: PropTypes.objectOf(PropTypes.any).isRequired,
        index: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            tooltipIsHovered: false,
        };
    }

    render() {
        const { car, index } = this.props;
        const { tooltipIsHovered } = this.state;
        const totalCarsCount = NewOrderStore.getSelectedCarsTotalCount();
        const carCount = NewOrderStore.getSelectedCarsCount(car.id);
        const carPrice = car.price;
        const selectedClass = carCount > 0 ? "custom-car custom-car--selected" : "custom-car";

        const checkDiscountFrom = (dataCollection) => {
            if (!dataCollection) return false;

            const getDates = NewOrderStore.getFormattedPickupDates();
            const pickupDate = moment(getDates.pickupDateTime);
            const discountMinDate = moment(dataCollection.thereDiscountMinDate);
            const discountMaxDate = moment(dataCollection.thereDiscountMaxDate);

            return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
        };

        const checkDiscountTo = (dataCollection) => {
            if (!dataCollection) return false;

            const getDates = NewOrderStore.getFormattedPickupDates();
            const pickupDate = moment(getDates.returnPickupDateTime);
            const discountMinDate = moment(dataCollection.backDiscountMinDate);
            const discountMaxDate = moment(dataCollection.backDiscountMaxDate);

            return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
        };

        const currentDiscount = checkDiscountFrom(car) ? car.thereDiscount : car.backDiscount;

        if (!(index >= NewOrderStore.showCustomCarsCount)) {
            return (
                <Card.Grid key={car.id} className={selectedClass}>
                    <div className="custom-car__header">
                        <div className="custom-car__info">
                            <div className="custom-car__counter">
                                {carCount > 0 && (
                                    <Tag style={{ fontWeight: "bold", fontSize: "16px" }} color="geekblue">
                                        {carCount}x
                                    </Tag>
                                )}
                                <span className="custom-car__title">
                                    {i18n.exists(car.nameWithDictionaryPrefix)
                                        ? i18n.t(car.nameWithDictionaryPrefix)
                                        : car.name}
                                </span>
                                {NewOrderStore.mode !== "hour-rental" && (
                                    <CarCategoryDescriptionToolTip carCategory={car} />
                                )}
                            </div>
                            <div className="custom-car__photo">
                                <GetCarImg item={car} />
                            </div>
                        </div>
                        <div className="custom-car__price">
                            {checkDiscountFrom(car) || checkDiscountTo(car) ? (
                                <div>
                                    <del>
                                        {CurrencyStore.selectedCurrency === CurrencyStore.CZK ? (
                                            <span style={{ fontSize: "13px" }}>
                                                {carPrice} {CurrencyStore.CZK}
                                            </span>
                                        ) : (
                                            !!(carPrice !== CurrencyStore.CZK && CurrencyStore.currencies) && (
                                                <span style={{ fontSize: "13px" }}>
                                                    {getAmountInForeignCurrency(carPrice, CurrencyStore.selectedCurrency)}
                                                </span>
                                            )
                                        )}
                                    </del>
                                        {!!(
                                            currentDiscount !== CurrencyStore.CZK &&
                                            CurrencyStore.currencies &&
                                            CurrencyStore.selectedCurrency !== CurrencyStore.CZK
                                        ) && (
                                            <span>
                                                {getAmountInForeignCurrency(
                                                    currentDiscount,
                                                    CurrencyStore.selectedCurrency
                                                )}
                                            </span>
                                        )}
                                        <span className="custom-car__price-small" >
                                        {currentDiscount} {CurrencyStore.CZK}
                                        </span>
                                </div>
                            ) : (
                                <div>
                                    {!!(
                                        carPrice !== CurrencyStore.CZK &&
                                        CurrencyStore.currencies &&
                                        CurrencyStore.selectedCurrency !== CurrencyStore.CZK
                                    ) && (
                                        <span>
                                            {getAmountInForeignCurrency(carPrice, CurrencyStore.selectedCurrency)}
                                        </span>
                                    )}
                                    <span className={CurrencyStore.selectedCurrency === CurrencyStore.CZK ? "custom-car__price-big" : "custom-car__price-small"}>
                                        {carPrice} {CurrencyStore.CZK}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="order-form__row">
                        <div className="order-form__top-car-info">
                            {carCount > 0 && car.capacity > 0 && car.luggageCapacity > 0 && (
                                <>
                                    <span className="white-space-nowrap">
                                        <Popover
                                            overlayClassName="order-form__popover"
                                            content={i18n.t("Kapacita")}
                                        >
                                            <UserOutlined style={{ color: "#005093" }} />
                                            <span className="order-form__top-car-info-label">
                                                {i18n.t("Kapacita:")}
                                            </span>{" "}
                                            <strong style={{ color: "#005093" }}>{carCount * car.capacity}</strong>
                                        </Popover>
                                    </span>
                                    <Divider type="vertical" />
                                    <span className="white-space-nowrap">
                                        <Popover
                                            overlayClassName="order-form__popover"
                                            content={getLuggageCapacityContent(carCount * car.luggageCapacity)}
                                        >
                                            <ShoppingOutlined style={{ color: "#005093" }} />
                                            <span className="order-form__top-car-info-label">
                                                {i18n.t("Zavazadel:")}
                                            </span>{" "}
                                            <strong style={{ color: "#005093" }}>{carCount * car.luggageCapacity}</strong>
                                        </Popover>
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="custom-car__select">
                            <div className="custom-car__controls">
                                <Button
                                    onClick={() => {
                                        NewOrderStore.removeCustomCar(car);
                                        NewOrderStore.filterExtras();
                                        NewOrderStore.setIfRouteHasToBePaidInAdvance();
                                    }}
                                    type="primary"
                                    icon={<MinusOutlined />}
                                    className="minus-passenger"
                                    disabled={carCount === 0}
                                />{" "}
                                <Popover
                                    onMouseEnter={() => this.setState({ tooltipIsHovered: true })}
                                    onMouseLeave={() => this.setState({ tooltipIsHovered: false })}
                                    open={(carCount >= 20 || totalCarsCount >= 25) && tooltipIsHovered}
                                    content={i18n.t(
                                        "Maximální počet aut jednoho typu je 20 a celkový počet aut je 25"
                                    )}
                                    title={false}
                                >
                                    <Button
                                        onClick={() => {
                                            NewOrderStore.addCustomCar(car);
                                            NewOrderStore.filterExtras();
                                        }}
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        className="plus-passenger"
                                        disabled={carCount >= 20 || totalCarsCount >= 25}
                                    />
                                </Popover>
                            </div>
                        </div>
                    </div>
                </Card.Grid>
            );
        }

        return null;
    }
}

export default observer(CustomCarSelect);
