import React, { Component } from "react";
import {
    Radio, List, Popover, Empty, Tag, 
} from "antd";
import {
    UserOutlined,
    ShoppingOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../core/i18n";
import CurrencyStore from "../../stores/Currency/CurrencyStore";
import NewOrderStore from "../../stores/Order/NewOrderStore";
import CarCategoryStore from "../../stores/CarCategory/CarCategoryStore";
import GetCarImg from "./GetCarImg";
import getAmountInForeignCurrency from "../../utils/newOrderStore/getAmountInForeignCurrency";
import UserListStore from "../../stores/User/UserListStore";
import getLuggageCapacityContent from "./getLuggageCapacityContent";
import moment from 'moment-timezone';

class TopCars extends Component {
    static propTypes = {
        carCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
        onSelect: PropTypes.func.isRequired,
        selectedCar: PropTypes.string,
    }

    state = {
        validatedSelectedCar: this.props.selectedCar,
    };

    componentDidMount() {
        this.verifySelectedCarExists(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.carCategories !== this.props.carCategories || prevProps.selectedCar !== this.props.selectedCar) {
            this.verifySelectedCarExists(false);
        }
    }

    verifySelectedCarExists(isInitialLoad) {
        const { carCategories, selectedCar } = this.props;
        const carExists = carCategories.some((item) => item.id.toString() === selectedCar);

        if (!carExists && selectedCar !== null) {
            // If no previous selection exists or it's the initial load, potentially reset or set a default selection
            this.setState({ validatedSelectedCar: null });
            if (!isInitialLoad) {
                NewOrderStore.resetSelectedCarsSelect();
                // Optionally, invoke any logic here that you would after a manual selection
            }
        } else if (carExists) {
            this.setState({ validatedSelectedCar: selectedCar }, () => {
                // If preserving selection and not initial load, trigger any side effects as if the user had made the selection
                if (!isInitialLoad) {
                    this.handleSelectionSideEffects(carCategories.find(item => item.id.toString() === selectedCar));
                }
            });
        }
    }

    handleSelectionSideEffects(selectedItem) {
        // Call any logic here that should happen after a car is selected, similar to onSelect
        this.props.onSelect(selectedItem);
        NewOrderStore.filterExtras();
        // Any other logic that needs to run after selecting a car
    }

    static defaultProps = {
        selectedCar: null,
    };

    getItemNameAndDescription(item) { // eslint-disable-line class-methods-use-this
        let itemName = "";
        let itemDescription = "";
        if (item.combination) {
            item.combination.forEach((carCategory) => {
                if (itemName !== "") {
                    itemName += ", ";
                }
                itemName += `${carCategory.carCount}x ${i18n.t(carCategory.nameWithDictionaryPrefix, { defaultValue: carCategory.name })}`;
                itemDescription += i18n.t(carCategory.descriptionWithDictionaryPrefix, { defaultValue: carCategory.description });
            });
        } else {
            itemName = i18n.t(item.nameWithDictionaryPrefix, { defaultValue: item.name });
            itemDescription = i18n.t(item.descriptionWithDictionaryPrefix, { defaultValue: item.description });
        }
        return { name: itemName, description: itemDescription };
    }

    getIsRetunrDiscountTag = (item) => {
        if (item.isreturnDiscount && item.discountPercentage > 0 && !UserListStore.user.isPartner) {
            return (
                <span style={{ display: 'inline-block', margin: '4px' }}>
                    <Tag color="#f5e79d" style={{ color: "#0d0c0c" }}>
                        {i18n.t("Discount for return")} <b style={{ color: "#7a4848" }}>{item.discountPercentage}%</b>
                    </Tag>
                </span>
            );
        }
    
        return null; // Do not show the tag if combinations are present or `isShowFirst` is false
    }

    getPayInAdvanceTag = (item) => {
        let requiresAdvancePayment = item.payAdvance;
    
        // Check if any car in the combination requires payment in advance
        if (item.combination) {
            requiresAdvancePayment = item.combination.some(carCategory => carCategory.payAdvance);
        }
    
        if (requiresAdvancePayment && !UserListStore.user.isPartner) {
            return (
                <span style={{ display: 'inline-block', margin: '4px' }}>
                    <Popover
                        content={i18n.t("Booking must be prepaid at least 24 hours in advance.")}
                        title={false}
                    >
                        <Tag color="orange">
                            {i18n.t("Payment in advance required")}
                        </Tag>
                    </Popover>
                </span>
            );
        }
    
        return null; // Return nothing if none of the cars require advance payment
    }

    getRefundTag = (item) => {
        let highestRefundCancellation = item.refundCancellation;
    
        // Get the highest refundCancellation value from the combination
        if (item.combination) {
            highestRefundCancellation = item.combination.reduce((max, carCategory) => {
                return Math.max(max, carCategory.refundCancellation);
            }, highestRefundCancellation);
        }
    
        if (highestRefundCancellation === null || highestRefundCancellation === undefined) {
            return null; // No tag when refundCancellation is not set (null or undefined)
        } else if (highestRefundCancellation === 0) {
            
            return (<span style={{ display: 'inline-block', margin: '4px' }}><Tag color="magenta">{i18n.t("non refundable")}</Tag></span>);
        } else {
            return (
                <span style={{ display: 'inline-block', margin: '4px' }}>
                    <Popover
                        content={`${i18n.t("A full refund will be issued if the cancellation is at least ")} ${highestRefundCancellation} ${i18n.t("hours in advance.")}`}
                        title={false}
                    >
                    <Tag color="#e7ffdd" style={{ color: "#0d0c0c" }}>
                        {i18n.t("Free-cancellation")} <b style={{ color: "#7a4848" }}>{highestRefundCancellation} {i18n.t("hrs")}</b>
                    </Tag>
                    </Popover>
                </span>
            );
        }
    }
    

    render() {
        const { carCategories, onSelect } = this.props;
        const { validatedSelectedCar } = this.state;
    
        const getPriceLabelKey = (item) => {
            if (UserListStore.user.isPartner) {
                return "Partnerská cena";
            } else {
                return "Jednosměrný transfer";
            }
        };
    
        const checkDiscountFrom = (dataCollection) => {
            if (!dataCollection) return false;
    
            const getDates = NewOrderStore.getFormattedPickupDates();
            const pickupDate = moment(getDates.pickupDateTime);
            const discountMinDate = moment(dataCollection.thereDiscountMinDate);
            const discountMaxDate = moment(dataCollection.thereDiscountMaxDate);
    
            return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
        };
    
        const checkDiscountTo = (dataCollection) => {
            if (!dataCollection) return false;
    
            const getDates = NewOrderStore.getFormattedPickupDates();
            const pickupDate = moment(getDates.returnPickupDateTime);
            const discountMinDate = moment(dataCollection.backDiscountMinDate);
            const discountMaxDate = moment(dataCollection.backDiscountMaxDate);
    
            return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
        };
    
        const isDateTimeSelected = NewOrderStore.formatPickupDateTimes();
    
        return (
            <Radio.Group value={validatedSelectedCar} style={{ width: "100%" }}>
                <List
                    itemLayout="horizontal"
                    bordered
                    style={{
                        borderColor: NewOrderStore.error && !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length
                            && !NewOrderStore.isLoading ? "#f5222d" : null,
                    }}
                >
                    {!isDateTimeSelected.pickupDateTime && !NewOrderStore.car && !NewOrderStore.isLoading && !NewOrderStore.backRouteStep && (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={i18n.t("Pro zobrazení dostupných vozidel nejdříve vyberte místo z-do, datum a čas.")}
                        />
                    )}
    
                    {carCategories.slice(0, NewOrderStore.showAllTopCarsCount).map((item) => {
                        const isSelected = item.id.toString() === validatedSelectedCar;
                        const currentDiscount = checkDiscountFrom(item) ? item.thereDiscount : item.backDiscount;
                        const nameAndDescription = this.getItemNameAndDescription(item);
    
                        return (
                            <List.Item
                                className="order-form__custom-car"
                                style={isSelected ? { boxShadow: "inset 0 0 1px 2px #005093", background: "#ffeaa71a", borderRadius: "5px" } : {}}
                                onClick={() => {
                                    onSelect(item);
                                    NewOrderStore.filterExtras();
                                }}
                                key={`car_${item.id}`} // Unique key
                            >
                                <Radio value={item.id.toString()} />
                                {NewOrderStore.mode !== "hour-rental" ? (
                                    <Popover
                                        content={nameAndDescription.description ? nameAndDescription.description : nameAndDescription.name}
                                        title={false}
                                    >
                                        <div className="order-form__custom-car-image">
                                            <GetCarImg item={item} />
                                        </div>
                                    </Popover>
                                ) : (
                                    <div className="order-form__custom-car-image">
                                        <GetCarImg item={item} />
                                    </div>
                                )}
                                <div className="order-form__top-car-info">
                                    <p className="order-form__top-car-title">
                                        {nameAndDescription.name}
                                    </p>
                                    <div>
                                        <UserOutlined style={{ color: "#005093" }} />
                                        <span>
                                            {i18n.t("Kapacita")}:{" "}
                                        </span>
                                        <strong style={{ color: "#005093" }}>{item.capacity}</strong>
                                    </div>
                                    <div>
                                        <Popover
                                            overlayClassName="order-form__popover"
                                            content={getLuggageCapacityContent(item.luggageCapacity)}
                                        >
                                            <ShoppingOutlined style={{ color: "#005093" }} />
                                            <span>
                                                {i18n.t("počet zavazadel")}:{" "}
                                            </span>
                                            <strong style={{ color: "#005093" }}>{item.luggageCapacity}</strong>
                                        </Popover>
                                    </div>
                                </div>
                                <div className="order-form__top-car-info_tags">
                                    {this.getIsRetunrDiscountTag(item)}
                                    {this.getRefundTag(item)}
                                    {this.getPayInAdvanceTag(item)}
                                </div>
                                <div className="order-form__top-car-price">
                                    <div className="order-form__price-label">
                                        {i18n.t(getPriceLabelKey(item))}
                                    </div>
                                    {checkDiscountFrom(item) || checkDiscountTo(item) ? (
                                        <>
                                            <del style={{ display: "flex", color: "#ff2400", fontSize: "13px", fontWeight: "normal" }}>
                                                {CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies &&
                                                    getAmountInForeignCurrency(item.price, CurrencyStore.selectedCurrency) && (
                                                        <span style={{ display: "inline-block" }}>
                                                            {getAmountInForeignCurrency(item.price, CurrencyStore.selectedCurrency)}
                                                        </span>
                                                )}
                                                {CurrencyStore.selectedCurrency === CurrencyStore.CZK && (
                                                    <span style={{ display: "inline-block" }}>
                                                        {item.price} {CurrencyStore.CZK}
                                                    </span>
                                                )}
                                            </del>
    
                                            {CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies &&
                                                getAmountInForeignCurrency(currentDiscount, CurrencyStore.selectedCurrency) ? (
                                                    <>
                                                        <span style={{ display: "block" }}>
                                                            {getAmountInForeignCurrency(currentDiscount, CurrencyStore.selectedCurrency)}
                                                        </span>
                                                        <span className="order-from-price-small">
                                                            {currentDiscount} {CurrencyStore.CZK}
                                                        </span>
                                                    </>
                                            ) : (
                                                CurrencyStore.selectedCurrency === CurrencyStore.CZK && (
                                                    <span style={{ display: "block" }}>
                                                        {currentDiscount} {CurrencyStore.CZK}
                                                    </span>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies &&
                                                getAmountInForeignCurrency(item.price, CurrencyStore.selectedCurrency) && (
                                                    <>
                                                        <span style={{ display: "block" }}>
                                                            {getAmountInForeignCurrency(item.price, CurrencyStore.selectedCurrency)}
                                                        </span>
                                                        <span className="order-from-price-small">
                                                            {item.price} {CurrencyStore.CZK}
                                                        </span>
                                                    </>
                                            )}
                                            {CurrencyStore.selectedCurrency === CurrencyStore.CZK && (
                                                <span style={{ display: "block" }}>
                                                    {item.price} {CurrencyStore.CZK}
                                                </span>
                                            )}
                                        </>
                                    )}
                                </div>
                            </List.Item>
                        );
                    })}
    
                    {carCategories.length === 0 && !CarCategoryStore.isLoading && isDateTimeSelected.pickupDateTime && (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={i18n.t("Žádné vozidla pro daný výběr")}
                        />
                    )}
                </List>
                {(NewOrderStore.error && !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length && !NewOrderStore.isLoading) && (
                    <span style={{ color: "#f5222d" }}>
                        {i18n.t("Vyberte vozidlo")}
                    </span>
                )}
            </Radio.Group>
        );
    }
    
    
}

export default observer(TopCars);
