import React from "react";

export default function FlightInformationOptions(allFlightInformation) {
    const options = allFlightInformation.map(flightInfo => {
        const parts = [];

        if (flightInfo.companyName && flightInfo.destination) {
            parts.push(`${flightInfo.companyName} - ${flightInfo.destination}`);
        } else if (flightInfo.companyName) {
            parts.push(flightInfo.companyName);
        } else if (flightInfo.destination) {
            parts.push(flightInfo.destination);
        }

        if (flightInfo.terminal) {
            parts.push(`Terminal ${flightInfo.terminal}`);
        }

        const detailsString = parts.join(" - ");

        return {
            value: flightInfo.flightNumber,
            label: (
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 3 }}>
                    <span>{flightInfo.flightNumber}</span>
                    <span style={{ fontSize: 11, color: '#7c7070', marginLeft: 5 }}>
                        {detailsString && `(${detailsString})`}
                    </span>
                </div>
            ),
        };
    });

    return options;
}
