import React from "react";
import { observer } from "mobx-react";
import {
    PhoneOutlined,
    WhatsAppOutlined,
    FormOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Divider, Spin } from "antd";
import i18n from "../../core/i18n";
import ContactUsModalStore from "../../stores/Order/ContactUsModalStore";
import SettingsStore from "../../stores/Settings/SettingsStore";

const Sidebar = observer(() => {
    const { settings, isLoading } = SettingsStore;
    const { imagecerTifikaturl, tripadvisorurl } = settings;

    return (
        <div>
            <div className="order-sidebar__box">
                <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">{i18n.t("Rezervace je bezpečná")}</h3>
                </div>
                <div>{i18n.t("Rezervaci můžete provést rovněž telefonicky na bezplatné lince")}</div>
                <div>
                    <Divider style={{ margin: "7px 0 0 0" }} />
                    <WhatsAppOutlined style={{ color: "#25D366" }} />
                    <Button
                        size="large"
                        type="link"
                        onClick={() => window.open("https://wa.me/447449011780", "_blank")}
                    >
                        +44 7449 011780
                    </Button>

                    <Divider style={{ margin: "0px 0" }} />
                    <PhoneOutlined style={{ color: "#170686" }} />
                    <Button
                        size="large"
                        type="link"
                        onClick={() => window.open("tel:+420222554211", "_blank")}
                    >
                        +420 222 554 211
                    </Button>

                    <Divider style={{ margin: "0px 0" }} />
                    <InfoCircleOutlined style={{ color: "#002943" }} />
                    <Button
                        size="large"
                        type="link"
                        onClick={() => window.open("https://www.prague-airport-transfers.co.uk/frequently-asked-questions/", "_blank")}
                    >
                        {i18n.t("FAQ")}
                    </Button>

                    <Divider style={{ margin: "0px 0" }} />
                    <FormOutlined style={{ color: "#002943" }} />
                    <Button
                        size="large"
                        type="link"
                        onClick={() => ContactUsModalStore.open()}
                    >
                        {i18n.t("Kontaktní formulář")}
                    </Button>
                </div>
            </div>
            <div className="order-sidebar__box">
                <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">{i18n.t("Doporučeno na")}</h3>
                </div>
                {tripadvisorurl && imagecerTifikaturl && (
                    <a
                        href={tripadvisorurl}
                        title={i18n.t("Doporučeno na")}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={imagecerTifikaturl}
                            border="0"
                            alt={i18n.t("Doporučeno na")}
                            style={{ maxWidth: '94%' }}
                        />
                    </a>
                )}
                {isLoading && (
                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                        <Spin size="small" />
                    </div>
                )}
            </div>
        </div>
    );
});

export default Sidebar;
