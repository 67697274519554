import React from "react";
import { observer } from "mobx-react";
import { Spin } from "antd";
import AutoCompleteLocation from "../../../../../autoCompleteLocation/AutoCompleteLocation";
import i18n from "../../../../../../core/i18n";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";

const onSearchStartHandle = (phrase) => { // eslint-disable-line class-methods-use-this
    NewOrderStore.start = null;
    NewOrderStore.startValue = phrase;
    CarCategoryStore.routeDistance = null;
    NewOrderStore.isstartRed = false;
    // NewOrderStore.calculatePrice();
};

const onSelectStartHandle = (value, routeNumber) => {
    const originalName = NewOrderStore.getOriginTranslation(value, "cs"); // Translate back to Czech
    //console.log("Start - Selected Translated Value:", value);
    //console.log("Start - Mapped Original Czech Name:", originalName);

    NewOrderStore.startValue = value;
    NewOrderStore.onSelectRoutePoint(originalName, routeNumber, "start"); // Use original Czech name
    NewOrderStore.isstartRed = false;
};

const AutoCompleteLocationStart = ({ placeHolder, routeNumber, defaultValue, ismapModal = true }) => {
    if (NewOrderStore.startValue === null) {
        return <Spin spinning />;
    }

    let startAddress = NewOrderStore.startValue;
    if (NewOrderStore.returnRouteMode) {
        routeNumber = 1;
        NewOrderStore.formValues.routes[routeNumber].startValue = NewOrderStore.startValue;
        startAddress = NewOrderStore.formValues.routes[routeNumber].startValue;
    } else {
        startAddress = NewOrderStore.startValue;
    }

    const disabled = NewOrderStore.backRouteStep && NewOrderStore.returnRouteMode;

    return (
        <AutoCompleteLocation
            id="placeFrom"
            isStart
            onSearch={onSearchStartHandle}
            onSelect={(value) => { onSelectStartHandle(value, routeNumber); }}
            style={{ width: "100%" }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 300 }}
            placeholder={i18n.t(placeHolder)}
            defaultValue={startAddress || ""}
            value={startAddress || ""}
            ismapModal={ismapModal}
            disabled={disabled}
        />
    );
};

export default observer(AutoCompleteLocationStart);
