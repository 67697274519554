import React from "react";
import PropTypes from "prop-types";
import PriceAndRecapDiscountWayThere from "./PriceAndRecapDiscountWayThere";
import PriceAndRecapDiscountWayBack from "./PriceAndRecapDiscountWayBack";

const PriceAndRecapDiscountBackRoute = (props) => {
    const { hasSelectedCarsZeroPrice, showForeignCurrency } = props;

    return (
        <React.Fragment>
            <PriceAndRecapDiscountWayThere
                hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                showForeignCurrency={showForeignCurrency}
            />

            <PriceAndRecapDiscountWayBack
                hasSelectedCarsZeroPrice={hasSelectedCarsZeroPrice}
                showForeignCurrency={showForeignCurrency}
            />
        </React.Fragment>
    );
};

PriceAndRecapDiscountBackRoute.propTypes = {
    hasSelectedCarsZeroPrice: PropTypes.bool.isRequired,
    showForeignCurrency: PropTypes.any.isRequired,
};

export default PriceAndRecapDiscountBackRoute;
