import React, { Fragment } from "react";
import {
    Popover, Form, Tag,
} from "antd";
import {
    ClockCircleOutlined,
    ShoppingOutlined,
    UserOutlined,
    WarningOutlined,
    BulbOutlined, // Import BulbOutlined icon
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import i18n from "../../../../../core/i18n";
import HelpButton from "../../../../HelpButton";
import CarCategoryStore from "../../../../../stores/CarCategory/CarCategoryStore";
import getRouteDuration from "../../../../../utils/newOrderStore/getRouteDuration";
import PaymentStore from "../../../../../stores/Payment/PaymentStore";
import ZoneStore from "../../../../../stores/Zone/ZoneStore";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import config from "../../../../../config/config";
import getLuggageCapacityContent from "../../../../order/getLuggageCapacityContent";

const FormItem = Form.Item;

const RenderExcursionPoints = ({ route, formItemLayout }) => route.excursionPoints.map((points, index) => {
    const isStart = index === 0;
    const isEnd = index === route.excursionPoints.length - 1;
    const isMiddle = !isStart && !isEnd;

    let text;

    if (isStart) {
        text = i18n.t("Nástupní místo");
    } else if (isEnd) {
        text = i18n.t("Místo ukončení");
    } else if (isMiddle) {
        text = i18n.t("Další zastávka");
    }
    return (
        <FormItem
            {...formItemLayout}
            className="non-bold-label" 
            label={text}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
        >
            <span className="highlighted-value">
                <span className="highlighted-value">{index + 1}</span>
                {". "}
                {points.place}
            </span>
            {!!(NewOrderStore.excursionPoints[index].waitingTime) && points.waitingTime > 0 && (
                <div className="highlighted-value" style={{ fontSize: "14px" }}>
                    <ClockCircleOutlined />
                    {i18n.t("Doba pobytu")}
                    {": "}
                    <span className="highlighted-value">
                        {points.waitingTime}
                        {points.waitingTime === 1
                            ? ` ${i18n.t("hodina")}`
                            : points.waitingTime >= 5
                                ? ` ${i18n.t("hodin")}`
                                : ` ${i18n.t("hodiny")}`}
                    </span>
                </div>
            )}
        </FormItem>
    );
});

const carsList = (route => (
    route.orderRouteCars.map(record => (
        <div key={record.carCategory.id}>
            <div className="order-form__confirm-car">
                <span className="highlighted-value" style={{ display: "inline-block" }}>
                    {record.carCount}
                    {"x"}
                </span>
                &nbsp;
                <span className="highlighted-value" style={{ display: "inline-block" }}>
                    {i18n.exists(record.carCategory.nameWithDictionaryPrefix) ? i18n.t(record.carCategory.nameWithDictionaryPrefix) : record.carCategory.name}
                </span>
                {!!(record.carCategory.luggageCapacity) && (
                    <Popover
                        overlayClassName="order-form__popover"
                        content={getLuggageCapacityContent(record.carCategory.luggageCapacity)}
                    >
                        <ShoppingOutlined className="ml-10" />
                        {record.carCategory.luggageCapacity}
                        <HelpButton />
                    </Popover>
                )}
            </div>
        </div>
    ))
));

const RouteSummary = ({ route, formItemLayout, index }) => {
    const extras = [];
    const paymentTypeId = route.paymentType;
    let paymentTypeName;

    const kidsString = () => {
        let kidString = "";
        if (route.kids1) {
            kidString = kidString.concat(`${route.kids1}x ${`${i18n.t("0-9 kg (do 1 roku)")}`}`);
        }
        if (route.kids2) {
            if (kidString !== "") {
                kidString = kidString.concat(", ");
            }
            kidString = kidString.concat(`${route.kids2}x ${i18n.t("9-18 kg (1-4 roky)")}`);
        }
        if (route.kids3) {
            if (kidString !== "") {
                kidString = kidString.concat(", ");
            }
            kidString = kidString.concat(`${route.kids3}x ${i18n.t("18-36 kg (4-8 roky)")}`);
        }
        return kidString;
    };

    PaymentStore.types.forEach((paymentInfo) => {
        if (paymentTypeId === paymentInfo.id) {
            paymentTypeName = paymentInfo.title;
        }
    });

    route.extras.forEach((extra) => {
        extras.push(extra);
    });

    const flightNumberLabel = ZoneStore.getFlightNumberLabel(index === 1);
    const fromLabel = NewOrderStore.mode === "hour-rental" ? "Místo přistavení" : "Z";
    const toLabel = NewOrderStore.mode === "hour-rental" ? "Místo ukončení" : "Do";

    const showUsePickupDateLiveIcon = (route.usePickupDateLive && NewOrderStore.mode === "order")
        && ((index === 0 && CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM)
            || (index === 1 && CarCategoryStore.returnZoneInfo === ZoneStore.TYPE_AIRPORT_FROM));

    const zoneInfoFinal = NewOrderStore.returnRouteMode ? CarCategoryStore.returnZoneInfo : CarCategoryStore.zoneInfo;

    const pickupDateLabel = NewOrderStore.usePickupDateLive
        && parseInt(zoneInfoFinal, 10) === ZoneStore.TYPE_AIRPORT_FROM ? i18n.t("Čas příletu") : i18n.t("Termín vyzvednutí");

    const returnDateLabel = CarCategoryStore.returnZoneInfo === ZoneStore.TYPE_AIRPORT_FROM ? i18n.t("Čas příletu") : i18n.t("Termín vyzvednutí");

    const RouteDistance = () => {
        if (route.distance && NewOrderStore.mode !== "excursion" && NewOrderStore.mode !== "hour-rental") {
            return (
                <span>
                    <p style={{ marginBottom: 10, padding: 5 }}>
                        <BulbOutlined style={{ marginRight: 8, color: "#005093" }}/>
                        {" "} 
                        <span style={{ fontSize: 13 }}>{i18n.t("Podle zvolené nástupní a cílové adresy je čas přepravy přibližně")}</span>
                        {" "}
                        <span>
                            <b style={{ fontSize: 13, color: "#025ba7" }}>{getRouteDuration(route.duration / 60)}</b>
                            {"."}
                        </span>
                        {" "}
                        <span style={{ fontSize: 13 }}>
                            {i18n.t("Čas se může měnit v závislosti na dopravní špičce a dalších omezeních. Přibližná vzdálenost")}
                        </span>
                        {" "}
                        <span><b style={{ fontSize: 13, color: "#025ba7" }}>{route.distance}</b></span>
                        {" "}
                        <span><b style={{ fontSize: 13, color: "#025ba7" }}>km</b>.</span>
                    </p>
                </span>
            );
        }
        return null;
    };

    return (
        <Fragment>
            {!route.excursionPoints && (
                <>
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t(fromLabel)}
                        className="non-bold-label" 
                    >
                        <span className="highlighted-value">{route.start}</span>
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label={i18n.t(toLabel)}
                        className="non-bold-label" 
                    >
                        <span className="highlighted-value">
                            {route.finish || i18n.t("Nevyplněno")}
                            {/* Add info icon with popover for RouteDistance */}
                            {route.distance && (
                                <Popover content={<RouteDistance />} placement="top">
                                    <BulbOutlined style={{ marginLeft: 8, color: "#005093", cursor: "pointer" }} />
                                </Popover>
                            )}
                        </span>
                    </FormItem>
                </>
            )}

            {route.excursionPoints && (
                <>
                    <RenderExcursionPoints formItemLayout={formItemLayout} route={route} />

                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Celková doba exkurze")}
                        className="non-bold-label" 
                    >
                        <span className="highlighted-value">{getRouteDuration(CarCategoryStore.routeTotalDuration)}</span>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Celková vzdálenost")}
                        className="non-bold-label" 
                    >
                        <span>
                            <span className="highlighted-value">{CarCategoryStore.routeDistance}</span>
                            &nbsp;
                            <span className="highlighted-value">km</span>
                        </span>
                    </FormItem>
                </>
            )}

            {route.rentHours && (
                <FormItem
                    {...formItemLayout}
                    label={i18n.t("Doba pronájmu")}
                    className="non-bold-label" 
                >
                    <span className="highlighted-value">{route.rentHours}{" "}{i18n.t("hodin")}</span>
                </FormItem>
            )}

            <FormItem
                {...formItemLayout}
                label={index === 1 ? returnDateLabel : pickupDateLabel}
                className="non-bold-label" 
            >
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                    <div className="mr-10 highlighted-value">
                        {route.pickupDate.locale(useRouter().query.lang.toString()).format(config.webDayNameFormat)}
                        &nbsp;
                        {route.pickupDate.format(config.webDateFormat)}
                    </div>
                    <div className="highlighted-value">
                        <ClockCircleOutlined />
                        {route.pickupDate.format(config.webTimeFormat)}
                        {showUsePickupDateLiveIcon && (
                            <Popover
                                placement="top"
                                content={
                                    i18n.t(
                                        "Náš řidič bude monitorovat čas příletu a vyzvedneme vás po čase aktuálního příletu bez ohledu na to zda let bude mít zpoždění nebo zrychlení."
                                    )
                                }
                            >
                                <HelpButton />
                            </Popover>
                        )}
                    </div>
                </div>
            </FormItem>
            {route.flightNumber && (
                <Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={flightNumberLabel}
                        className="non-bold-label" 
                    >
                        <span className="highlighted-value">{route.flightNumber}</span>
                    </FormItem>
                </Fragment>
            )}

            {!route.flightNumber && showUsePickupDateLiveIcon && (
                <Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={flightNumberLabel}
                        className="non-bold-label" 
                    >
                        <span style={{ color: "#c02222" }}>
                            <WarningOutlined />
                            {i18n.t("Není vyplněno číslo letu")}
                        </span>
                        <Popover
                            placement="top"
                            content={
                                i18n.t(
                                    "Pokud neznáte vaše číslo letu, zadejte alespoň leteckou společnost a místo vašeho odletu"
                                )
                            }
                        >
                            <HelpButton />
                        </Popover>
                    </FormItem>
                </Fragment>
            )}

            {route.trainNumber && (
                <Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Číslo vlaku")}
                        className="non-bold-label" 
                    >
                        <span className="highlighted-value">{route.trainNumber}</span>
                    </FormItem>
                </Fragment>
            )}

            {!route.trainNumber && ((index === 0 && CarCategoryStore.zoneInfo === ZoneStore.TYPE_TRAIN_FROM) || (index === 1 && CarCategoryStore.returnZoneInfo === ZoneStore.TYPE_TRAIN_FROM)) && (
                <Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Číslo vlaku")}
                        className="non-bold-label" 
                    >
                        <span style={{ color: "#c02222" }}>
                            <WarningOutlined />
                            {i18n.t("Není vyplněno číslo vlaku")}
                        </span>
                        <Popover
                            placement="top"
                            content={
                                i18n.t(
                                    "Náš řidič bude monitorovat čas příjezdu vašeho vlaku v případě zpoždění."
                                )
                            }
                        >
                            <HelpButton />
                        </Popover>
                    </FormItem>
                </Fragment>
            )}

            {route.busNumber && (
                <Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Číslo autobusu")}
                        className="non-bold-label" 
                    >
                        <span className="highlighted-value">{route.busNumber}</span>
                    </FormItem>
                </Fragment>
            )}

            {!route.busNumber && ((index === 0 && CarCategoryStore.zoneInfo === ZoneStore.TYPE_BUS_FROM) || (index === 1 && CarCategoryStore.returnZoneInfo === ZoneStore.TYPE_BUS_FROM)) && (
                <Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Číslo autobusu")}
                        className="non-bold-label" 
                    >
                        <span style={{ color: "#c02222" }}>
                            <WarningOutlined />
                            {i18n.t("Není vyplněno číslo autobusu")}
                        </span>
                    </FormItem>
                </Fragment>
            )}

            <FormItem
                {...formItemLayout}
                label={i18n.t("Počet osob")}
                className="non-bold-label" 
            >
                <span className="highlighted-value">
                    <UserOutlined />
                    {route.passengersCount}
                </span>
            </FormItem>
            {(route.kids1 || route.kids2 || route.kids3) ? (
                <FormItem
                    {...formItemLayout}
                    label={i18n.t("Dítě")}
                    className="non-bold-label" 
                >
                    <span className="highlighted-value">
                        {kidsString()}
                    </span>
                </FormItem>
            ) : null}

            <FormItem
                {...formItemLayout}
                label={i18n.t("Vozidla")}
                className="non-bold-label" 
            >
                {carsList(route)}
            </FormItem>

            {!!extras.length && (
                <FormItem
                    {...formItemLayout}
                    label={i18n.t("Zboží")}
                    className="non-bold-label" 
                >
                    <div className="highlighted-value">
                        {extras.map(extra => (
                            <div key={extra.id}>
                                {extra.amount}
                                {"x"}
                                {" "}
                                {extra.item}
                            </div>
                        ))}
                    </div>
                </FormItem>
            )}

            {route.noteForDriver && (
                <FormItem
                    {...formItemLayout}
                    label={i18n.t("Poznámka")}
                    className="non-bold-label" 
                >
                    <React.Fragment>
                        {route.noteForDriver ? <div className="highlighted-value">{route.noteForDriver}</div> : null}
                    </React.Fragment>
                </FormItem>
            )

            }

            {CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM && (route.withLuggage
                || route.withoutLuggage
                || route.excessiveLuggage) ? (
                    <FormItem
                        {...formItemLayout}
                        label={i18n.t("Zavazadla")}
                        className="non-bold-label" 
                    >
                        <React.Fragment>
                            {route.withoutLuggage ? (
                                <div className="highlighted-value">
                                    {i18n.t("Bez odbavených zavazadel")}
                                    <Popover
                                        overlayClassName="order-form__popover"
                                        placement="top"
                                        content={i18n.t("If you carry just cabin bags, mark this option.")}
                                    >
                                        <HelpButton />
                                    </Popover>
                                </div>
                            ) : null}
                            {route.withLuggage && !route.excessiveLuggage ? (
                                <div className="highlighted-value">
                                    {i18n.t("Odbavená zavazadla")}
                                    <Popover
                                        overlayClassName="order-form__popover"
                                        placement="top"
                                        content={i18n.t("If you hold any check-in luggage, mark this option.")}
                                    >
                                        <HelpButton />
                                    </Popover>
                                </div>
                            ) : null}
                            {route.excessiveLuggage ? (
                                <div className="highlighted-value">
                                    {i18n.t("Nadměrná zavazadla")}
                                    <Popover
                                        overlayClassName="order-form__popover"
                                        placement="top"
                                        content={i18n.t("If you hold excessive check-in luggage, mark this option.")}
                                    >
                                        <HelpButton />
                                    </Popover>
                                </div>
                            ) : null}
                        </React.Fragment>
                    </FormItem>
                )
                : null
            }

            <FormItem
                {...formItemLayout}
                label={i18n.t("Způsob platby")}
                className="non-bold-label" 
            >
                <span className="highlighted-value">{route.paymentType === PaymentStore.TYPE_PAY_LATER_NAME ? i18n.t("Zaplatím později") : i18n.t(paymentTypeName)}</span>
            </FormItem>

            {/* <FormItem
                {...formItemLayout}
                label={i18n.t("Preferovaná měna")}
            >
                <span>{route.paymentCurrency}</span>
            </FormItem> */}
        </Fragment>

    );
};

RouteSummary.propTypes = {
    formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    route: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RouteSummary;
