import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import NewOrderStore from "../../../../stores/Order/NewOrderStore";

import RenderExcursion from "./routePoints/RenderExcursion";
import RenderSimple from "./routePoints/RenderSimple";

class RoutePoints extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
        routeNumber: PropTypes.number.isRequired,
    }

    render() {
        const { form, routeNumber } = this.props;
        const { mode } = NewOrderStore;
        this.form = form;

        if (mode === "excursion") {
            return (
                <RenderExcursion
                    routeNumber={routeNumber}
                    locations={this.locations}
                    startLocationOptions={this.startLocationOptions || []}
                />
            );
        }
        return (
            <RenderSimple
                routeNumber={routeNumber}
                startLocationOptions={this.startLocationOptions}
                finishLocationOptions={this.finishLocationOptions}
                mode={mode}
            />
        );
    }
}

export default observer(RoutePoints);