import React from "react";
import CurrencyStore from "../../../../stores/Currency/CurrencyStore";


const RenderPrice = ((amount, price) => {
    const totalPrice = amount * price;
    return (
        <>
           {!!(price && amount && CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies) && (
                <span>
                    {(Math.round((price / CurrencyStore.currencies[CurrencyStore.selectedCurrency].rate) * amount * 100) / 100)}
                    &nbsp;
                    {CurrencyStore.selectedCurrency}
                    &nbsp;
                    /
                    &nbsp;
                </span>
            )}
            <span>
                {totalPrice}
                &nbsp;
                {CurrencyStore.CZK}
            </span>

        </>
    );
});

export default RenderPrice;
