import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import {
    Row, Col, Divider, Tabs, message, 
} from "antd";
import { withRouter } from "next/router";
import { Form } from "@ant-design/compatible";
import {
    PushpinOutlined,
    HourglassOutlined,
    RetweetOutlined,
    PictureOutlined,
} from "@ant-design/icons";
import NewOrderFormSchema from "../../../forms/Order/NewOrderFormSchema";
import OrderStep1Page from "./OrderStep1Page";
import OrderStep2Page from "./OrderStep2Page";
import OrderTourPage from "../Tours/OrderTourPage";
import NewOrderStore from "../../../stores/Order/NewOrderStore";
import Sidebar from "../../order/Sidebar";
import i18n from "../../../core/i18n";
import NotificationStore from "../../../stores/NotificationStore";
import InvalidAddressModal from "./orderStep1Page/InvalidAddressModal";
import ContactUsModal from "./orderStep1Page/ContactUsModal";
import { pushRoute } from "../../Router.tsx";
import RouteDuplicateErrorModal from "../../orderList/RouteDuplicateErrorModal";

class OrderPage extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
    };


    componentDidMount() {
        NewOrderStore.mode = this.getActiveTab(this.props.router);
        this.handleRegistrationMessage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (NewOrderStore.showRegistrationInfo) {
            this.handleRegistrationMessage();
        }
    }

    handleRegistrationMessage = () => {
        if (NewOrderStore.showRegistrationInfo) {
            message.info({
                content: `${i18n.t("K registraci dojde po odeslání první objednávky.")}`,
                duration: 5,
                onClose: () => {
                    NewOrderStore.showRegistrationInfo = false;
                }
            });
        }
    }


    getActiveTab(router) { // eslint-disable-line class-methods-use-this
        const { pathname } = router; // Router.location.pathname;

        let activeTab = "order";
        if (pathname.includes("hour-rental")) {
            activeTab = "hour-rental";
        } else if (pathname.includes("excursion")) {
            activeTab = "excursion";
        } else if (pathname.includes("Zajezdy")) {
            activeTab = "Zajezdy";
        }
        return activeTab;
    }

    render() {
        const activeTab = this.getActiveTab(this.props.router);
        const { form } = this.props;

        this.form = new NewOrderFormSchema(this.props.router, form, activeTab);

        const { step } = NewOrderStore;

        const renderTabs = (stepTemp, mode = null) => (
            <div className="content">
                {stepTemp === 1 && <OrderStep1Page form={this.form} mode={mode} />}
                {stepTemp === 2 && <OrderStep2Page form={this.form} mode={mode} />}
            </div>
        );

        const tabItems = [
            {
                label: i18n.t("Transfery"),
                key: "order",
                children: renderTabs(step),
                icon: <RetweetOutlined />,
            },
            {
                label: i18n.t("Hodinový pronájem"),
                key: "hour-rental",
                children: renderTabs(step, "hour-rental"),
                icon: <HourglassOutlined />,
            },
            {
                label: i18n.t("Exkurze"),
                key: "excursion",
                children: renderTabs(step, "excursion"),
                icon: <PushpinOutlined />,
            },
            {
                label: i18n.t("Zajezdy"),
                key: "Zajezdy",
                children: <OrderTourPage />,
                icon: <PictureOutlined />,
            }];

        return (
            <div style={{ background: "#fff" }}>
                <RouteDuplicateErrorModal form={this.form} />
                <InvalidAddressModal />
                <ContactUsModal />
                <Row gutter={30}>
                    <Col xs={24} sm={24} md={24} lg={18}>

                        <div style={{
                            marginTop: 12, marginBottom: 12, marginLeft: 20, marginRight: 20,
                        }}
                        >
                        </div>

                        <Tabs
                            defaultActiveKey={activeTab}
                            className="order-type"
                            moreIcon={false}
                            items={tabItems}
                            onChange={(key) => {
                                NewOrderStore.reset();
                                if (key === "excursion" || key === "hour-rental") {
                                    NewOrderStore.backRoute = false;
                                }
                                NewOrderStore.mode = key;
                                NotificationStore.reset();
                                NewOrderStore.resetForm = true;
                                key = key === "order" ? "index" : key; // eslint-disable-line no-param-reassign

                                const params = { lang: this.props.router.query.lang };
                                if (key !== "index") {
                                    params.path = key;
                                }


                                pushRoute(key, params, { shallow: true });
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <div className="order-sidebar">
                            <Sidebar />

                            {step === 1 && (
                                <>
                                    <div className="order-sidebar__box">
                                        <div className="order-sidebar__headline">
                                            <h3 className="order-sidebar__title">{i18n.t("Free extras")}</h3>
                                        </div>
                                        <li><a href={i18n.t("free tour - url")}  target="_blank">{i18n.t("Free tour voucher")}</a></li>
                                        <li><a href={i18n.t("guide book - url")}  target="_blank">{i18n.t("Guidebook & map")}</a></li>
                                        <li>{i18n.t("Free bottled water")}</li>
                                        <li>{i18n.t("Wi-fi hotspot")}</li>
                                        <li>{i18n.t("Quality service")}</li>
                                        <p>
                                            {i18n.t(
                                                "Veškeré promo materiály zahrnující mapy, průvodce Prahou,"
                                                                                + " voucher na 4 hodinovou free túru Prahou atd. Obdržíte přímo od našeho řidiče."
                                            )}
                                        </p>
                                    </div>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <div className="order-sidebar__box">
                                        <div className="order-sidebar__headline">
                                            <h3 className="order-sidebar__title">{i18n.t("Užitečné informace")}</h3>
                                        </div>
                                        <h6 className="order-sidebar__subtitle">{i18n.t("Jak funguje proces rezervace")}</h6>
                                        <p>{i18n.t("Instrukce")}</p>
                                        <Divider />
                                        <h6 className="order-sidebar__subtitle">{i18n.t("Free Cancellation")}</h6>
                                        <p>
                                            {i18n.t("Most services can be cancelled anytime free of charge with no deposit required")}
                                        </p>
                                    </div>
                                </>
                            )}

                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(withRouter(observer(OrderPage)));
