import React from "react";
import { Input } from "antd";
import i18n from "../../core/i18n";
import BaseFormSchema from "../BaseFormSchema";
import InvalidAddressModalStore from "../../stores/Order/InvalidAddressModalStore";
import InputInt from "../../components/form/InputInt";

const { TextArea } = Input;


export default class InvalidAddressFormSchema extends BaseFormSchema {
    constructor(form) {
        super(form);

        this.form = form;
        this.getFieldDecorator = form.getFieldDecorator;

        this.items = {
            ...this.items,
            invalidAddressStart: {
                id: "invalidAddressStart",
                label: i18n.t("Z"),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte místo z") }] },
                element: <Input placeholder={i18n.t("Z")} />,
            },

            invalidAddressFinish: {
                id: "invalidAddressFinish",
                label: i18n.t("Do"),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte místo do") }] },
                element: <Input placeholder={i18n.t("Do")} />,
            },

            passengersCount: {
                id: "passengersCount",
                label: i18n.t("Počet cestujících"),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte Počet cestujících") }] },
                element: <InputInt min={1} placeholder={i18n.t("Počet cestujících")} style={{ width: "100%" }} />,
            },

            note: {
                id: "note",
                label: i18n.t("Poznámka"),
                options: {},
                element: <TextArea rows="1" placeholder={i18n.t("Poznámka")} />,
            },
        };
    }

    onSubmit() {
        this.form.validateFields((err, values) => {
            if (!err) {
                InvalidAddressModalStore.sendEmail(values);
            }
        });
    }
}
