import React, { Component } from "react";
import { observer } from "mobx-react";
import {
    Row, Col, Form, Button,
} from "antd";
import PropTypes from "prop-types";
import RoutePoints from "./orderStep1Page/RoutePoints";
import UserStore from "../../../stores/UserStore";
import UserListStore from "../../../stores/User/UserListStore";
import NewOrderStore from "../../../stores/Order/NewOrderStore";
import ExtraStore from "../../../stores/Extra/ExtraStore";
import i18n from "../../../core/i18n";
import OrderFormSteps from "./orderStep1Page/OrderFormSteps";
import PassengersCountAndRentHours from "./orderStep1Page/form/PassengersCountAndRentHours";
import CarsList from "./orderStep1Page/form/CarList/CarsList";
import PickUpDate from "./orderStep1Page/form/PickUpDate";
import ChildrenPart from "./orderStep1Page/form/ChildrenCard";
import Extras from "./orderStep1Page/form/Extras";
import Notes from "./orderStep1Page/form/Notes";
import MethodOfPayment from "./orderStep1Page/form/MethodOfPayment";
import PriceAndRecap from "./orderStep1Page/form/PriceAndRecap/PriceAndRecap";
import PriceAndRecapDiscount from "./orderStep1Page/form/PriceAndRecap/PriceAndRecapDiscount";
import Contact from "./orderStep1Page/form/Contact";
import RouteErrorModal from "../../orderList/RouteErrorModal";

// useStrict(true);
class OrderStep1Page extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
        mode: PropTypes.string,
    };

    static defaultProps = {
        mode: null,
    };

    constructor(props) {
        super(props);
        NewOrderStore.myRef = React.createRef();
    }

    state = {
        viewport: 0,
    };

    componentDidMount() {
        //PaymentStore.loadTypes();

        this.componentIsMounted = true;
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if (UserStore.user) {
            UserListStore.loadUser();
        }

        this.init();

        NewOrderStore.setRouteByUrlParameter();

        //SettingsStore.load();
    }

    componentDidUpdate() {
        const { form } = this.props;
        if (NewOrderStore.returnRouteMode && NewOrderStore.returnRouteModeInit) {
            this.init();
            NewOrderStore.returnRouteModeInit = false;
        }

        if (NewOrderStore.resetForm) {
            form.resetFields();
            NewOrderStore.resetForm = false;
        }
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    continueButton = () => {
        if (NewOrderStore.backRouteStep && !NewOrderStore.returnRouteMode) {
            return i18n.t("Pokračovat a zadat cestu zpět");
        } if (NewOrderStore.addReturnRoute) {
            return i18n.t("Odeslat rezervaci zpáteční cesty");
        }

        return i18n.t("Pokračovat na souhrn");
    };

    resize() {
        if (this.componentIsMounted) {
            this.setState({ viewport: window.innerWidth });
        }
    }

    init() {
        const { mode } = this.props;
        NewOrderStore.setCheckDuplicateError(true);
        NewOrderStore.clearDuplicateRouteIds();
        NewOrderStore.mode = mode || "order";

        //ExtraStore.loadCategories();
        ExtraStore.setCallback((extras) => {
            if (NewOrderStore.formValues.routes && NewOrderStore.formValues.routes.length && NewOrderStore.formValues.routes[0].extras) {
                NewOrderStore.formValues.routes[0].extras.forEach((extraData) => {
                    const extra = extras.find(extraTemp => extraTemp.id === extraData.id);
                    extra.amount = extraData.amount;
                });
            }
            NewOrderStore.calculatePrice();
        });
    }

    render() {
        const routeNumber = 0;
        const { form, mode } = this.props;
        this.form = form;
        const { formValues } = NewOrderStore;

        if (!formValues.routes || !formValues.routes.length) {
            formValues.routes = [];
            formValues.routes.push({
                excursionPoints: [],
            });
        }
        const formItemLayout = {
            colon: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 },
            },
        };
        const formItemLayoutCount = {
            colon: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
            },
        };

        const { viewport } = this.state;

        const onClickHandle = () => {
            const {
                start, startValue, finish, finishValue,
            } = NewOrderStore;

            if (NewOrderStore.returnRouteMode) {
                NewOrderStore.start = finish;
                NewOrderStore.startValue = finishValue;
                NewOrderStore.finish = start;
                NewOrderStore.finishValue = startValue;
                NewOrderStore.loadCarCategoriesAndSetPaymentType();
            } else {
                NewOrderStore.start = start;
                NewOrderStore.startValue = startValue;
                NewOrderStore.finish = finish;
                NewOrderStore.finishValue = finishValue;
            }

            NewOrderStore.backRouteStepBackButton = false;
            NewOrderStore.returnRouteMode = false;
            NewOrderStore.returnRouteModeInit = false;
            NewOrderStore.endPriceCars = NewOrderStore.priceCars;
            NewOrderStore.priceCars = NewOrderStore.startPriceCars;
            NewOrderStore.setCheckDuplicateError(true);
            NewOrderStore.clearDuplicateRouteIds();
            NewOrderStore.preventRouteByUrlParameter = true;
        };

        const isDiscount = () => {
            if (!NewOrderStore.returnRouteMode && NewOrderStore.car?.thereDiscount) {
                return true;
            }

            if (NewOrderStore.returnRouteMode && NewOrderStore.car?.backDiscount) {
                return true;
            }

            return false;
        };

        const isSurcharges = () => {
            if (!NewOrderStore.returnRouteMode && NewOrderStore.car?.thereSurcharges) {
                return true;
            }

            if (NewOrderStore.returnRouteMode && NewOrderStore.car?.backSurcharges) {
                return true;
            }

            return false;
        };

        return (
            <div className="content__inner">

                <OrderFormSteps viewport={viewport} />

                <Form className="order-form" onFinish={() => { form.onSubmit(); }} noValidate>

                    <PickUpDate
                        form={form}
                        formItemLayout={formItemLayout}
                        formValues={formValues}
                        routeNumber={routeNumber}
                    />

                    <PassengersCountAndRentHours
                        key={routeNumber}
                        routeNumber={routeNumber}
                        formValues={formValues}
                        formItemLayout={formItemLayoutCount}
                        form={form}
                    />

                    <RoutePoints mode={mode} form={this.form} routeNumber={routeNumber} formValues={formValues} />

                    <CarsList />

                    {NewOrderStore.car &&
                        <Extras formValues={formValues} />
                    }

                    <ChildrenPart
                        form={form}
                        formItemLayout={formItemLayout}
                        formValues={formValues}
                        routeNumber={routeNumber}
                    />

                    {NewOrderStore.car &&
                    <Notes
                        form={form}
                        formValues={formValues}
                        routeNumber={routeNumber}
                    />
                    }

                    {((NewOrderStore.backRoute && NewOrderStore.backRouteStep && NewOrderStore.returnRouteMode)
                        || !NewOrderStore.backRoute
                        || (NewOrderStore.backRoute && !NewOrderStore.backRouteStep)) && (
                        <MethodOfPayment />
                    )}

                    {NewOrderStore.car && isDiscount || NewOrderStore.car && isSurcharges() || (NewOrderStore.car && NewOrderStore.car.isreturnDiscount && UserListStore.user && !UserListStore.user.isPartner)
                        ? <PriceAndRecapDiscount /> : NewOrderStore.car ? <PriceAndRecap /> : null }

                    <Contact form={form} formValues={formValues} />

                    <Row type="flex" align="center" className="order-form__summary-footer">

                        <Col sm={12} xs={24}>
                            {(!NewOrderStore.editOrder && NewOrderStore.backRouteStepBackButton) && (
                                <Button
                                    className="order-form__summary-footer-back"
                                    onClick={() => onClickHandle()}
                                >
                                    {i18n.t("Zpět")}
                                </Button>
                            )}
                        </Col>


                        <Col className="text-align--right" sm={12} xs={24}>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                disabled={!UserStore.canOrderContinue()}
                            >
                                {i18n.t(this.continueButton())}
                            </Button>
                        </Col>
                    </Row>
                    <RouteErrorModal form={form} />

                </Form>

            </div>
        );
    }
}

export default observer(OrderStep1Page);