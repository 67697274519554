import React from "react";
import {
    Input, Popover, Select, DatePicker, TimePicker, Button, AutoComplete, InputNumber, Row, Col,  Switch, 
} from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { toJS } from "mobx";
import {
    CalendarOutlined,
    ClockCircleOutlined,
    MinusOutlined,
    PlusOutlined,
    UserOutlined,
    HourglassOutlined,
    WarningOutlined 
} from "@ant-design/icons";
import moment from "moment-timezone";
import PhoneInput from "react-phone-input-2";
import BaseFormSchema from "../BaseFormSchema";
import HelpButton from "../../components/HelpButton";
import StampIcon from "../../components/StampIcon";
import NewOrderStore from "../../stores/Order/NewOrderStore";
import UserListStore from "../../stores/User/UserListStore";
import ExtraStore from "../../stores/Extra/ExtraStore";
import LanguageStore from "../../stores/Language/LanguageStore";
import i18n from "../../core/i18n";
import ZoneStore from "../../stores/Zone/ZoneStore";
import UserStore from "../../stores/UserStore";
import InputInt from "../../components/form/InputInt";
import countries from "../../core/countries";
import CarCategoryStore from "../../stores/CarCategory/CarCategoryStore";
import FlightInformationOptions from "../../utils/flightInformation/FlightInformationOptions";
import FlightInformationStore from "../../stores/FlightInformationStore";
import NotificationStore from "../../stores/NotificationStore";
import config from "../../config/config";
import SettingsStore from "../../stores/Settings/SettingsStore";
import OrdersListStore from "../../stores/Order/OrdersListStore";
import getTotalPrice from "../../utils/newOrderStore/getTotalPrice";
import {
    checkDiscountFrom, checkDiscountTo, checkSurchargesFrom, checkSurchargesTo,
} from "../../utils/newOrderStore/getDiscountSurcharge";

const { Option } = Select;

let prefixLength = null;

let flightInformationTimeout;
function getFlightInformationWithTimeout(phrase, isArrive, ftr) {
    if (flightInformationTimeout) {
        clearTimeout(flightInformationTimeout);
    }
    flightInformationTimeout = setTimeout(() => {
        FlightInformationStore.getFlightInformation(phrase, isArrive, ftr);
    }, 500);
}

function getFlightInformationWithTimeoutonDateTime(routeNumber, form, isArrive) {
    try {
        if (form && form.getFieldValue) {
            if (routeNumber === 2) {
                routeNumber = 1; 
            }
            console.log('Form instance:', form);
            const phrase = form.getFieldValue(`routes[${routeNumber}][flightNumber]`);
            console.log('Retrieved phrase:', phrase);
            if (phrase && phrase.length <= 5) {
                return false;
            }

            if (flightInformationTimeout) {
                clearTimeout(flightInformationTimeout);
            }
            flightInformationTimeout = setTimeout(() => {
                FlightInformationStore.getFlightInformation(phrase, isArrive, routeNumber);
            }, 500);
        } else {
            console.error("Form instance is not available or form.getFieldValue is not a function");
        }
    } catch (e) {
        console.log('Error in fetching flight information:', e);
    }
}


function checkBabyseats(allData) {
    const totalAllowed = allData.passengersCount > 1 ? allData.passengersCount - 1 : allData.passengersCount;
    const totalSelected = NewOrderStore.kids1[NewOrderStore.getRouteWay()] + NewOrderStore.kids2[NewOrderStore.getRouteWay()] + NewOrderStore.kids3[NewOrderStore.getRouteWay()];


    if (allData && totalSelected <= totalAllowed) {
        return totalAllowed;
    }
    return 0;
}

function checkBabybtn(allData) {
    const totalAllowed = allData.passengersCount;
    const totalSelected = NewOrderStore.kids1[NewOrderStore.getRouteWay()] + NewOrderStore.kids2[NewOrderStore.getRouteWay()] + NewOrderStore.kids3[NewOrderStore.getRouteWay()];

    if (allData && totalSelected < totalAllowed) {
        return totalAllowed;
    }
    return 0;
}


class NewOrderFormSchema extends BaseFormSchema {
    constructor(router, form, mode = null) {
        super(form);

        this.router = router;
        this.form = form;
        this.getFieldDecorator = form.getFieldDecorator;
        this.selectedStart = null;
        this.mode = mode;


        const usePickupDateLiveCondition = !!(NewOrderStore.usePickupDateLive && CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM);
        const trainLabelCondition = !!(CarCategoryStore.zoneInfo === ZoneStore.TYPE_TRAIN_FROM);
        const busLabelCondition = !!(CarCategoryStore.zoneInfo === ZoneStore.TYPE_BUS_FROM);


        const countriesOptions = countries.map(country => {
            if (!country || typeof country !== 'object') {
                console.error('Invalid country data:', country);
            }
            return (
                <Select.Option key={country.code} value={country.code}>
                    {country.name}
                </Select.Option>
            );
        });
        

        this.items = {

            "customerData[firstName]": {
                id: "customerData[firstName]",
                label: i18n.t("Jméno"),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte jméno") },
                    { 
                    pattern: /^[\p{L}\s'.-]+$/u, 
                    message: i18n.t("Pouze písmena, mezery, pomlčky, apostrofy a tečky jsou povoleny.") 
                  }] },
                element: (
                    <Row gutter={20}>
                        <Col span={8}>
                            <Select
                                style={{ width: "100%" }}
                                value={i18n.t(NewOrderStore.tagNameParts.greeting)}
                                onSelect={(value) => {
                                    NewOrderStore.tagNameParts.greeting = value;
                                }}
                            >
                                <Option id="man" key={i18n.t("Pan")}>{i18n.t("Pan")}</Option>
                                <Option id="woman" key={i18n.t("Paní")}>{i18n.t("Paní")}</Option>
                            </Select>
                        </Col>
                        <Col span={16}>
                            <Input
                                style={{ width: "100%" }}
                                placeholder={i18n.t("Jméno")}
                                value={NewOrderStore.tagNameParts.firstName}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    NewOrderStore.tagNameParts.firstName = value;
                                }}
                            />
                        </Col>
                    </Row>
                ),
            },
            "customerData[lastName]": {
                id: "customerData[lastName]",
                label: i18n.t("Příjmení"),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte příjmení") },
                    { 
                    pattern: /^[\p{L}\s'.-]+$/u, 
                    message: i18n.t("Pouze písmena, mezery, pomlčky, apostrofy a tečky jsou povoleny.") 
                  }] },
                element: <Input
                    style={{ width: "100%" }}
                    placeholder={i18n.t("Příjmení")}
                    value={NewOrderStore.tagNameParts.lastName}
                    onChange={(e) => {
                        const { value } = e.target;
                        NewOrderStore.tagNameParts.lastName = value;
                    }}
                />,
            },
            "customerData[tagName]": (CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM) ? {
                id: "customerData[tagName]",
                label:
                    <span>
                        <StampIcon />
                        <span style={{ fontSize: "16px" }}>
                            &nbsp;
                            {i18n.t("Cestující (jméno na cedulce)")}
                            &nbsp;
                        </span>
                        <Popover
                            placement="top"
                            overlayClassName="order-form__popover"
                            content={i18n.t("Při Vašem příletu do Prahy na Vás bude v příletové hale čekat náš řidič, který bude v ruce držet kartu s Vaším vytištěným jménem")}
                        >
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte jméno cestujícího") }] },
                element: <Input style={{ width: "100%" }} placeholder={i18n.t("Cestující (jméno na cedulce)")} />,
            } : {
                id: "customerData[tagName]",
                label:
                    <span>
                        <span>{i18n.t("Cestující (jméno na cedulce)")}</span>
                    </span>,
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte jméno cestujícího") },
                    { 
                    pattern: /^[\p{L}\s'.-]+$/u, 
                    message: i18n.t("Pouze písmena, mezery, pomlčky, apostrofy a tečky jsou povoleny.") 
                  }] },
                element: <Input style={{ width: "100%" }} placeholder={i18n.t("Cestující (jméno na cedulce)")} />,
            },
            "customerData[phone]": {
                id: "customerData[phone]",
                options: {},
                element: phone => (
                    <PhoneInput
                        country={LanguageStore.getPhonePrefix(router.query.lang)}
                        inputProps={{
                            name: "phone",
                            id: "phone",
                        }}
                        prefix="+"
                        searchPlaceholder={i18n.t("search")}
                        initialValue={NewOrderStore.contactPhone}
                        enableSearch
                        countryCodeEditable
                        // eslint-disable-next-line no-return-assign
                        onChange={(_value, country) => {
                            prefixLength = (country.dialCode).length;
                            NewOrderStore.contactPhone = `+${_value}`;
                            NewOrderStore.formValues.customerData.phone = `+${_value}`;
                            NewOrderStore.isPhoneaaded = `+${_value}`;
                        }
                        }
                    />
                )
                ,
            },
            "customerData[email]": {
                id: "customerData[email]",
                label: (
                    <span>
                        {i18n.t("E-mail")}
                        <Popover
                            placement="top"
                            overlayClassName="order-form__popover"
                            content={i18n.t("Prosím ujistěte se, že zadaný email je správný")}
                        >
                            <HelpButton />
                        </Popover>
                    </span>
                ),
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte email") }] },
                element: (
                    <Input
                        type="email"
                        style={{ width: "100%" }}
                        placeholder={i18n.t("Email-placeholder")}
                        value={NewOrderStore.tagNameParts.email}
                        onKeyUp={(e) => {
                            // Call checkEmail when the user stops typing
                            UserStore.checkEmail(e.target.value);
                        }}
                        onChange={(e) => {
                            const { value } = e.target;
                            NewOrderStore.tagNameParts.email = value;
                        }}
                    />
                ),
            },
            "customerData[loginHash]": {
                id: "customerData[loginHash]",
                label: (
                    <span>
                        {i18n.t("Ověřovací kód")}
                        <Popover
                            placement="top"
                            overlayClassName="order-form__popover"
                            content={i18n.t("Prosím zadejte ověřovací kód.")}
                        >
                            <HelpButton />
                        </Popover>
                    </span>
                ),
                options: { rules: [{ required: UserStore.sentRegisterHash, message: i18n.t("Prosím vyplňte ověřovací kód") }] },
                element: (
                    <Input
                        style={{ width: "100%" }}
                        placeholder={i18n.t("Ověřovací kód")}
                        onKeyUp={(e) => {
                            UserStore.verifyRegisterHash(e.target.value);
                        }}
                    />
                ),
            },
            "customerData[identificationNumber]": {
                id: "customerData[identificationNumber]",
                label:
                    <span>
                        {i18n.t("IČO")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyplňte vaše identifikační číslo")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [
                    { 
                    pattern: /^[\p{L}\p{N}\s&,'./#-]+$/u, 
                    message: i18n.t("Obsahuje nepovolené znaky.") 
                  }] },
                element: <Input type="text" style={{ width: "100%" }} placeholder={i18n.t("Vyplňte IČO")} />,
            },
            "customerData[taxIdentificationNumber]": {
                id: "customerData[taxIdentificationNumber]",
                label:
                    <span>
                        {i18n.t("DIČ")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyplňte vaše daňové identifikační číslo")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: {},
                element: <Input type="text" style={{ width: "100%" }} placeholder={i18n.t("Vyplňte DIČ")} />,
            },
            "customerData[companyName]": {
                id: "customerData[companyName]",
                label:
                    <span>
                        {i18n.t("Název společnosti")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyplňte název společnosti")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte název společnosti") },
                    { 
                    pattern: /^[\p{L}\p{N}\s&,'./#-]+$/u, 
                    message: i18n.t("Obsahuje nepovolené znaky.") 
                  }] },
                element: <Input type="text" style={{ width: "100%" }} placeholder={i18n.t("Název společnosti")} />,
            },
            "customerData[companyAddress][city]": {
                id: "customerData[companyAddress][city]",
                label:
                    <span>
                        {i18n.t("Město")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyplňte město ve kterém se nachází společnost")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [
                    { 
                    pattern: /^[\p{L}\p{N}\s&,'./#-]+$/u, 
                    message: i18n.t("Obsahuje nepovolené znaky.") 
                  }] },
                element: <Input type="text" style={{ width: "100%" }} placeholder={i18n.t("Město")} />,
            },
            "customerData[companyAddress][street]": {
                id: "customerData[companyAddress][street]",
                label:
                    <span>
                        {i18n.t("Ulice a ČP")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyplňte Ulici ve kterém se nachází společnost")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [
                    { 
                    pattern: /^[\p{L}\p{N}\s&,'./#-]+$/u, 
                    message: i18n.t("Obsahuje nepovolené znaky.") 
                  }] },
                element: <Input type="text" style={{ width: "100%" }} placeholder={i18n.t("Ulice a ČP")} />,
            },
            "customerData[companyAddress][country]": {
                id: "customerData[companyAddress][country]",
                label:
                    <span>
                        {i18n.t("Země")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyberte Zemi ve kterém se nachází společnost")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [
                    { 
                    pattern: /^[\p{L}\p{N}\s&,'./#-]+$/u, 
                    message: i18n.t("Obsahuje nepovolené znaky.") 
                  }] },
                element:
                    <Select style={{ width: "100%" }} showSearch optionFilterProp="children" placeholder={i18n.t("Vyberte zemi")}>
                        {countriesOptions}
                    </Select>,
            },
            "customerData[companyAddress][zip]": {
                id: "customerData[companyAddress][zip]",
                label:
                    <span>
                        {i18n.t("PSČ")}
                        <Popover placement="top" overlayClassName="order-form__popover" content={i18n.t("Vyplňte PSČ Města ve kterém se nachází společnost")}>
                            <HelpButton />
                        </Popover>
                    </span>,
                options: { rules: [
                    { 
                    pattern: /^[\p{L}\p{N}\s&,'./#-]+$/u, 
                    message: i18n.t("Obsahuje nepovolené znaky.") 
                  }] },
                element: <Input type="text" style={{ width: "100%" }} placeholder={i18n.t("PSČ")} />,
            },
        };

        function disabledDate(current) {
            return (current && current < moment().startOf("day") || current && current > moment().add(364, "d").startOf("day"));
        }

        function disabledDate2(current) {
            let startDay = moment().startOf("day");
            if (NewOrderStore && NewOrderStore.pickupDate) {
                startDay = NewOrderStore.pickupDate;
            }
            return (current && current < startDay || current && current > moment().add(364, "d").startOf("day"));
        }

        let routeNumber = 0;
        while (routeNumber < 2) {
            this.items[`routes[${routeNumber}][passengersCount]`] = {
                id: `routes[${routeNumber}][passengersCount]`,
                label: <span className="order-from-passengers">
                    <UserOutlined />
                    &nbsp;
                    {i18n.t("Počet cestujících")}
                    <Popover
                        overlayClassName="order-form__popover"
                        placement="top"
                        content={i18n.t("Počet osob ve vaší skupině včetně kojenců a dětí")}
                    >
                        <HelpButton />
                    </Popover>
                </span>,
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte počet cestujících") }] },
                element:
                    <div className="input-count">
                        <InputInt
                            min={1}
                            max={200}
                            value={NewOrderStore.passengersCount}
                            placeholder={i18n.t("Počet cestujících")}
                            onChange={(value) => {
                                NewOrderStore.passengersCount = value;
                                NewOrderStore.loadCarCategoriesWithTimeout();
                            }}
                        />
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    if (NewOrderStore.passengersCount > 1) {
                                        const oldpassengersCount = parseInt(NewOrderStore.passengersCount);
                                        NewOrderStore.passengersCount = oldpassengersCount - 1;
                                        NewOrderStore.loadCarCategoriesWithTimeout();
                                    }
                                }}
                                type="primary"
                                icon={<MinusOutlined />}
                                className="input-count__minus"
                            />
                            <Button
                                onClick={() => {
                                    const oldpassengersCount = parseInt(NewOrderStore.passengersCount);
                                    NewOrderStore.passengersCount = oldpassengersCount + 1;
                                    NewOrderStore.loadCarCategoriesWithTimeout();
                                }}
                                type="primary"
                                icon={<PlusOutlined />}
                                className="input-count__plus"
                            />
                        </Button.Group>
                    </div>,
            };

            const rentHoursIndex = `routes[${routeNumber}][rentHours]`;
            this.items[rentHoursIndex] = {
                id: rentHoursIndex,
                label: <span style={{ fontWeight: 500, fontSize: "18px" }}>
                    <HourglassOutlined />
                    &nbsp;
                    {i18n.t("Doba pronájmu")}
                       </span>,
                options: { rules: [{ required: true, message: i18n.t("Prosím vyplňte dobu pronájmu") }] },
                element:
                    <div className="input-count">
                        <InputNumber
                            min={1}
                            max={16}
                            value={NewOrderStore.rentHours}
                            style={{ width: "calc(100% - 63px)" }}
                            placeholder={i18n.t("Doba pronájmu")}
                            addonAfter={i18n.t("hodin")}
                            onChange={(value) => {
                                if (value > 16) {
                                    NewOrderStore.rentHours = 16;
                                } else {
                                    NewOrderStore.rentHours = value;
                                }
                                NewOrderStore.loadCarCategoriesWithTimeout();
                            }}
                        />
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    if (NewOrderStore.rentHours > 1) {
                                        const oldrentHours = parseInt(NewOrderStore.rentHours);
                                        NewOrderStore.rentHours = oldrentHours - 1;
                                        NewOrderStore.loadCarCategoriesWithTimeout();
                                    }
                                }}
                                type="primary"
                                icon={<MinusOutlined />}
                                className="input-count__minus"
                            />
                            <Button
                                onClick={() => {
                                    const oldrentHours = parseInt(NewOrderStore.rentHours);
                                    NewOrderStore.rentHours = oldrentHours + 1;
                                    NewOrderStore.loadCarCategoriesWithTimeout();
                                }}
                                type="primary"
                                icon={<PlusOutlined />}
                                className="input-count__plus"
                            />
                        </Button.Group>
                    </div>,
            };

            this.items[`routes[${routeNumber}][time]`] = {
                id: `routes[${routeNumber}][time]`,
                label: i18n.t("Doba pobytu"),
                options: { initialValue: "0" },
                element: <TimePicker
                    needConfirm={false}
                    allowClear={false}
                    format={config.webDateFormat}
                    placeholder="Doba pobytu"
                />,
            };

            this.items[`routes[${routeNumber}][pickupDate]`] = {
                id: `routes[${routeNumber}][pickupDate]`,
                label:
                    <span style={{ fontWeight: 500 }}>
                        <CalendarOutlined />
                        &nbsp;
                        {i18n.t("Datum")}
                        &nbsp;
                    </span>,
                options: { rules: [{ required: true, message: i18n.t("Prosím vyberte datum") }] },
                element: <DatePicker
                    disabledDate={(routeNumber == 0) ? disabledDate : disabledDate2}
                    format={config.webDateFormat}
                    style={{ width: "100%" }}
                    placeholder={i18n.t("Vyberte datum")}
                    allowClear={false}
                    className="back-pickup-term"
                    onChange={(date) => {
                        NewOrderStore.returnPickupDate = date;
                        if (NewOrderStore.returnPickupTime) { NewOrderStore.loadCarCategoriesAndSetPaymentType(); }
                        getFlightInformationWithTimeoutonDateTime(routeNumber,this.form);
                    }}
                />,
            };

            this.items[`routes[${routeNumber}][pickupTime]`] = {
                id: `routes[${routeNumber}][pickupTime]`,
                label:
                    <span style={{ fontWeight: 500 }}>
                        <ClockCircleOutlined />
                        {i18n.t("Čas")}
                    </span>,
                options: { rules: [{ initialValue: moment("12:00", config.webDateFormat), required: true, message: i18n.t("Prosím vyberte čas") }] },
                element: <TimePicker
                    format="HH:mm"
                    needConfirm={false}
                    style={{ width: "100%" }}
                    minuteStep={(CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM || CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_TO) ? 5 : 1}
                    placeholder={i18n.t("Vyberte čas")}
                    allowClear={false}
                    onChange={(time) => {
                        NewOrderStore.returnPickupTime = time;
                        if (NewOrderStore.returnPickupDate && !moment(NewOrderStore.returnPickupTime).isSame(time, "minute")) {
                            NewOrderStore.loadCarCategoriesAndSetPaymentType();
                        }
                        getFlightInformationWithTimeoutonDateTime(routeNumber,this.form);
                    }}
                />,
            };

            const flightNumberLabel = ZoneStore.getFlightNumberLabel(NewOrderStore.returnRouteMode);
            
            const ftr=routeNumber;
            this.items[`routes[${routeNumber}][flightNumber]`] = {
                id: `routes[${routeNumber}][flightNumber]`,
                label:
                    <span>
                        <span className="stamp-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="0 0 640 512"><path d="M.3 166.9L0 68C0 57.7 9.5 50.1 19.5 52.3l35.6 7.9c10.6 2.3 19.2 9.9 23 20L96 128l127.3 37.6L181.8 20.4C178.9 10.2 186.6 0 197.2 0h40.1c11.6 0 22.2 6.2 27.9 16.3l109 193.8 107.2 31.7c15.9 4.7 30.8 12.5 43.7 22.8l34.4 27.6c24 19.2 18.1 57.3-10.7 68.2c-41.2 15.6-86.2 18.1-128.8 7L121.7 289.8c-11.1-2.9-21.2-8.7-29.3-16.9L9.5 189.4c-5.9-6-9.3-14-9.3-22.5zM32 448H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32zm160-80c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm64 48c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" /></svg>
                        </span>
                        &nbsp;
                        {i18n.t(flightNumberLabel)}
                        &nbsp;
                        <Popover
                            overlayClassName="order-form__popover"
                            placement="top"
                            content={i18n.t("Pokud neznáte vaše číslo letu, zadejte alespoň leteckou společnost a místo vašeho odletu")}
                        >
                            <HelpButton />
                        </Popover>
                    </span>,
                options: {
                    rules: [{
                        required: usePickupDateLiveCondition,
                        message: i18n.t("Prosím vyberte číslo letu"),
                    },
                    { 
                    pattern: /^[\p{L}\p{N}\s-]+$/u, 
                    message: i18n.t("Pouze čísla, písmena, mezery a pomlčky jsou povoleny.")
                  }],
                },
                element:
                    <AutoComplete
                        onChange={(value) => {
                            const isArrive2 = ZoneStore.getFlightNumberType(NewOrderStore.returnRouteMode);
                            getFlightInformationWithTimeout(value, isArrive2,ftr);
                           
                        }}
                        placeholder={i18n.t("XX 9999 Example")}
                        style={{ width: "100%" }}
                        options={
                            FlightInformationOptions(
                              FlightInformationStore.flightInformation.filter(
                                info => info.isArrive === ZoneStore.getFlightNumberType(NewOrderStore.returnRouteMode)
                              )
                            )
                          }
                        dropdownStyle={{ width: 'auto' }}
                    />,
            };

            // Update for `withoutLuggage`
            this.items[`routes[${routeNumber}][withoutLuggage]`] = {
                id: `routes[${routeNumber}][withoutLuggage]`,
                options: { initialValue: NewOrderStore.defaultValues.withoutLuggage },
                label: (
                    <span>
                        {i18n.t("Bez odbavených zavazadel")}
                        <span className="order-form__hide_mobile">
                            <Popover
                                overlayClassName="order-form__popover"
                                placement="top"
                                content={i18n.t("If you carry just cabin bags, mark this option.")}
                            >
                                <HelpButton />
                            </Popover>
                        </span>
                    </span>
                ),
                element: (
                    <Switch
                        checked={NewOrderStore.defaultValues.withoutLuggage}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(checked) => {
                            NewOrderStore.defaultValues.withoutLuggage = checked;
                            NewOrderStore.defaultValues.withLuggage = !checked;
                            if (checked) {
                                NewOrderStore.defaultValues.excessiveLuggage = false;
                            }
                        }}
                    />
                ),
            };

            // Update for `withLuggage`
            this.items[`routes[${routeNumber}][withLuggage]`] = {
                id: `routes[${routeNumber}][withLuggage]`,
                options: { initialValue: NewOrderStore.defaultValues.withLuggage },
                label: (
                    <span >
                        {i18n.t("Odbavená zavazadla")}
                        <span className="order-form__hide_mobile">
                            <Popover
                                overlayClassName="order-form__popover"
                                placement="top"
                                content={i18n.t("If you hold any check-in luggage, mark this option.")}
                            >
                                <HelpButton />
                            </Popover>
                        </span>
                    </span>
                ),
                element: (
                    <Switch
                        checked={NewOrderStore.defaultValues.withLuggage}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(checked) => {
                            NewOrderStore.defaultValues.withLuggage = checked;
                            NewOrderStore.defaultValues.withoutLuggage = !checked;
                            if (!checked) {
                                NewOrderStore.defaultValues.excessiveLuggage = false;
                            }
                        }}
                    />
                ),
            };


            // Update for `excessiveLuggage`
            this.items[`routes[${routeNumber}][excessiveLuggage]`] = {
                id: `routes[${routeNumber}][excessiveLuggage]`,
                options: { initialValue: NewOrderStore.defaultValues.excessiveLuggage },
                label: (
                    <span>
                        {i18n.t("Nadměrná zavazadla")}
                        <span className="order-form__hide_mobile">
                            <Popover
                                overlayClassName="order-form__popover"
                                placement="top"
                                content={i18n.t("If you hold excessive check-in luggage, mark this option.")}
                            >
                                <HelpButton />
                            </Popover>
                        </span>
                    </span>
                ),
                element: (
                    <Switch
                        checked={NewOrderStore.defaultValues.excessiveLuggage}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        disabled={NewOrderStore.defaultValues.withoutLuggage}
                        onChange={(checked) => {
                            NewOrderStore.defaultValues.excessiveLuggage = checked;
                            if (checked) {
                                NewOrderStore.defaultValues.withLuggage = true;
                            }
                        }}
                    />
                ),
            };

            this.items[`routes[${routeNumber}][busNumber]`] = {
                id: `routes[${routeNumber}][busNumber]`,
                label:
                    <span style={{ fontWeight: 500 }}>
                        {i18n.t("Číslo autobusu")}
                    </span>,
                options: {
                    rules: [{
                        required: busLabelCondition,
                        message: i18n.t("Prosím vyplňte číslo autobusu"),
                    },
                    { 
                        pattern: /^[\p{L}\p{N}\s-]+$/u, 
                        message: i18n.t("Pouze čísla, písmena, mezery a pomlčky jsou povoleny.")
                    }],
                },
                element: <Input type="text" className="bus-number" style={{ width: "100%" }} placeholder={i18n.t("Číslo autobusu")} />,
            };

            this.items[`routes[${routeNumber}][trainNumber]`] = {
                id: `routes[${routeNumber}][trainNumber]`,
                label:
                    <span style={{ fontWeight: 500 }}>
                        {i18n.t("Číslo vlaku")}
                    </span>,
                options: {
                    rules: [{
                        required: trainLabelCondition,
                        message: i18n.t("Prosím vyplňte číslo vlaku"),
                    },
                    { 
                        pattern: /^[\p{L}\p{N}\s-]+$/u, 
                        message: i18n.t("Pouze čísla, písmena, mezery a pomlčky jsou povoleny.")
                    }],
                },
                element: <Input type="text" className="train-number" style={{ width: "100%" }} placeholder={i18n.t("Číslo vlaku")} />,
            };


            const iconStyle = { 
                width: '18px', 
                height: '18px', 
                marginRight: '8px', 
                display: 'inline-block', 
                verticalAlign: 'middle' 
            };
            
            this.items[`routes[${routeNumber}][kids1]`] = {
                id: `routes[${routeNumber}][kids1]`,
                label: i18n.t("0-9 kg (do 1 roku)"),
                options: { initialValue: NewOrderStore.kids1[NewOrderStore.getRouteWay()] },
                element: (
                    <div className="input-count" style={{ display: 'flex', alignItems: 'center' }}>
                        <img 
                            src="/img/seats/car-seat-0.svg" 
                            style={iconStyle} 
                        />
                        <InputInt
                            name={`routes[${routeNumber}][kids1]`}
                            min={0}
                            max={checkBabyseats(NewOrderStore)}
                            value={NewOrderStore.kids1[NewOrderStore.getRouteWay()]}
                            style={{ width: "100%" }}
                            placeholder={i18n.t("Zadejte počet")}
                            onChange={(value) => {
                                NewOrderStore.kids1[NewOrderStore.getRouteWay()] = value;
                            }}
                        />
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    if (NewOrderStore.kids1[NewOrderStore.getRouteWay()] > 0) {
                                        NewOrderStore.kids1[NewOrderStore.getRouteWay()] -= 1;
                                    }
                                }}
                                type="primary"
                                icon={<MinusOutlined />}
                                className="input-count__minus"
                            />
                            <Button
                                onClick={() => {
                                    if (checkBabybtn(NewOrderStore) > 0) {
                                        NewOrderStore.kids1[NewOrderStore.getRouteWay()] += 1;
                                    }
                                }}
                                type="primary"
                                icon={<PlusOutlined />}
                                className="input-count__plus"
                            />
                        </Button.Group>
                    </div>
                ),
            };
            
            this.items[`routes[${routeNumber}][kids2]`] = {
                id: `routes[${routeNumber}][kids2]`,
                label: i18n.t("9-18 kg (1-4 roky)"),
                options: { initialValue: NewOrderStore.kids2[NewOrderStore.getRouteWay()] },
                element: (
                    <div className="input-count" style={{ display: 'flex', alignItems: 'center' }}>
                        <img 
                            src="/img/seats/car-seat-1.svg" 
                            style={iconStyle} 
                        />
                        <InputInt
                            min={0}
                            max={checkBabyseats(NewOrderStore)}
                            value={NewOrderStore.kids2[NewOrderStore.getRouteWay()]}
                            style={{ width: "100%" }}
                            placeholder={i18n.t("Zadejte počet")}
                            onChange={(value) => {
                                NewOrderStore.kids2[NewOrderStore.getRouteWay()] = value;
                            }}
                        />
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    if (NewOrderStore.kids2[NewOrderStore.getRouteWay()] > 0) {
                                        NewOrderStore.kids2[NewOrderStore.getRouteWay()] -= 1;
                                    }
                                }}
                                type="primary"
                                icon={<MinusOutlined />}
                                className="input-count__minus"
                            />
                            <Button
                                onClick={() => {
                                    if (checkBabybtn(NewOrderStore) > 0) {
                                        NewOrderStore.kids2[NewOrderStore.getRouteWay()] += 1;
                                    }
                                }}
                                type="primary"
                                icon={<PlusOutlined />}
                                className="input-count__plus"
                            />
                        </Button.Group>
                    </div>
                ),
            };
            
            this.items[`routes[${routeNumber}][kids3]`] = {
                id: `routes[${routeNumber}][kids3]`,
                label: i18n.t("18-36 kg (4-8 roky)"),
                options: { initialValue: NewOrderStore.kids3[NewOrderStore.getRouteWay()] },
                element: (
                    <div className="input-count" style={{ display: 'flex', alignItems: 'center' }}>
                        <img 
                            src="/img/seats/car-seat-2.svg" 
                            style={iconStyle} 
                        />
                        <InputInt
                            min={0}
                            max={checkBabyseats(NewOrderStore)}
                            value={NewOrderStore.kids3[NewOrderStore.getRouteWay()]}
                            style={{ width: "100%" }}
                            placeholder={i18n.t("Zadejte počet")}
                            onChange={(value) => {
                                NewOrderStore.kids3[NewOrderStore.getRouteWay()] = value;
                            }}
                        />
                        <Button.Group>
                            <Button
                                onClick={() => {
                                    if (NewOrderStore.kids3[NewOrderStore.getRouteWay()] > 0) {
                                        NewOrderStore.kids3[NewOrderStore.getRouteWay()] -= 1;
                                    }
                                }}
                                type="primary"
                                icon={<MinusOutlined />}
                                className="input-count__minus"
                            />
                            <Button
                                onClick={() => {
                                    if (checkBabybtn(NewOrderStore) > 0) {
                                        NewOrderStore.kids3[NewOrderStore.getRouteWay()] += 1;
                                    }
                                }}
                                type="primary"
                                icon={<PlusOutlined />}
                                className="input-count__plus"
                            />
                        </Button.Group>
                    </div>
                ),
            };

            this.items[`routes[${routeNumber}][noteForDriver]`] = {
                id: `routes[${routeNumber}][noteForDriver]`,
                trigger: "onBlur",
                options: {},
                element: <Input.TextArea
                    style={{ borderColor: "#e8e8e8", borderRadius: 0, width: "100%" }}
                    placeholder={i18n.t("Text poznámky")}
                    autosize={{ minRows: 2, maxRows: 6 }}
                />,
            };

            this.items[`routes[${routeNumber}][paymentCurrency]`] = {
                id: `routes[${routeNumber}][paymentCurrency]`,
                label: i18n.t("Měna"),
                options: { initialValue: "EUR" },
                element:
                    <Select
                        style={{ width: "100%" }}
                        onChange={(value) => {
                            NewOrderStore.paymentCurrency = value;
                        }}
                    >
                        <Option value="CZK">CZK</Option>
                        <Option value="EUR">EUR</Option>
                        <Option value="GBP">GBP</Option>
                        <Option value="USD">USD</Option>
                    </Select>,
            };

            routeNumber += 1;
        }

        this.items["routes[0][pickupDate]"].element = (
            // options: {rules: [{required: true, message: i18n.t("Prosím vyberte datum") }] }
            <DatePicker
                disabledDate={disabledDate}
                format={config.webDateFormat}
                style={{ width: "100%" }}
                placeholder={i18n.t("Vyberte datum")}
                allowClear={false}
                onChange={(date) => {
                    NewOrderStore.pickupDate = date;
                    if (NewOrderStore.pickupTime) { NewOrderStore.loadCarCategoriesAndSetPaymentType(); }
                    getFlightInformationWithTimeoutonDateTime(0,this.form);
                }}
            />
        );

        this.items["routes[0][pickupTime]"] = {
            id: "routes[0][pickupTime]",
            label: (
                <span style={{ fontWeight: 500 }}>
                    <ClockCircleOutlined />
                    &nbsp;{i18n.t("Čas")}
                </span>
            ),
            options: {
                rules: [{ required: true, message: i18n.t("Prosím vyberte čas") }],
            },
            element: (
                <TimePicker
                    format="HH:mm"
                    style={{ width: "100%" }}
                    needConfirm={false}
                    allowClear={false}
                    minuteStep={
                        CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM ||
                        CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_TO
                            ? 5
                            : 1
                    }
                    placeholder={i18n.t("Vyberte čas")}
                    onChange={(time) => {
                        if (time) {
                            NewOrderStore.pickupTime = time;
                            if (NewOrderStore.pickupDate) {
                                NewOrderStore.loadCarCategoriesAndSetPaymentType();
                            }
                            getFlightInformationWithTimeoutonDateTime(0,this,form);
                        }
                    }}
                />
            ),
        };

        this.items["routes[1][pickupTime]"] = {
            id: "routes[1][pickupTime]",
            label: (
                <span style={{ fontWeight: 500 }}>
                    <ClockCircleOutlined />
                    &nbsp;{i18n.t("Čas")}
                </span>
            ),
            options: {
                rules: [{ required: true, message: i18n.t("Prosím vyberte čas") }],
            },
            element: (
                <TimePicker
                    format="HH:mm"
                    needConfirm={false}
                    style={{ width: "100%" }}
                    allowClear={false}
                    minuteStep={
                        CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM ||
                        CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_TO
                            ? 5
                            : 1
                    }
                    placeholder={i18n.t("Vyberte čas")}
                    onChange={(time) => {
                        if (time) {
                            NewOrderStore.returnPickupTime = time;
                            if (NewOrderStore.returnPickupDate) {
                                NewOrderStore.loadCarCategoriesAndSetPaymentType();
                            }
                        }
                    }}
                />
            ),
        };

        this.items["routes[0][pickupTime]"].options = {
            rules: [{
                required: true,
                message: i18n.t("Prosím vyberte čas"),
            }],
        };

        this.items["routes[1][pickupTime]"].options = {
            rules: [{
                required: true,
                message: i18n.t("Prosím vyberte čas"),
            }],
        };

        const flightNumberLabel = ZoneStore.getFlightNumberLabel(true);
        this.items["routes[1][flightNumber]"] = {
            id: "routes[1][flightNumber]",
            label:
                <span style={{ fontWeight: 500 }}>
                    <span className="stamp-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 640 512"><path d="M381 114.9L186.1 41.8c-16.7-6.2-35.2-5.3-51.1 2.7L89.1 67.4C78 73 77.2 88.5 87.6 95.2l146.9 94.5L136 240 77.8 214.1c-8.7-3.9-18.8-3.7-27.3 .6L18.3 230.8c-9.3 4.7-11.8 16.8-5 24.7l73.1 85.3c6.1 7.1 15 11.2 24.3 11.2H248.4c5 0 9.9-1.2 14.3-3.4L535.6 212.2c46.5-23.3 82.5-63.3 100.8-112C645.9 75 627.2 48 600.2 48H542.8c-20.2 0-40.2 4.8-58.2 14L381 114.9zM0 480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z" /></svg>
                    </span>
                    &nbsp;
                    {i18n.t(flightNumberLabel)}
                    &nbsp;
                    <Popover
                        overlayClassName="order-form__popover"
                        placement="top"
                        content={i18n.t("Pokud neznáte vaše číslo letu, zadejte alespoň leteckou společnost a místo vašeho odletu")}
                    >
                        <HelpButton />
                    </Popover>
                </span>,
            options: {
                rules: [{
                    required: usePickupDateLiveCondition,
                    message: i18n.t("Prosím vyberte číslo letu"),
                }],
            },
            element:
                
                <AutoComplete
                    onChange={(value) => {
                        const isArrive1 = ZoneStore.getFlightNumberType(true);
                        getFlightInformationWithTimeout(value, isArrive1,1);
                    }}

                    placeholder={i18n.t("XX 9999 Example 2")}
                    style={{ width: "100%" }}
                    options={
                        FlightInformationOptions(
                          FlightInformationStore.flightInformation.filter(
                            info => info.isArrive === ZoneStore.getFlightNumberType(true)
                          )
                        )
                      }
                    dropdownStyle={{ width: 'auto' }}
                />,
        };
    }
    /* eslint-disable no-param-reassign */

    onSubmit() {
        NewOrderStore.isNotice1 = false;
    
        this.form.validateFields(async (err, values) => {
            if (values.customerData) {
                let tagName = values.customerData.tagName ? values.customerData.tagName.trim() : '';
                const greeting = NewOrderStore.tagNameParts.greeting;
                const translatedGreeting = i18n.t(greeting);
    
                if (tagName === translatedGreeting) {
                    const firstName = values.customerData.firstName ? values.customerData.firstName.trim() : '';
                    const lastName = values.customerData.lastName ? values.customerData.lastName.trim() : '';
                    tagName = `${translatedGreeting} ${firstName} ${lastName}`;
                    values.customerData.tagName = tagName;
                    NewOrderStore.setTagName(tagName);
                }
            }
    
            if (!err && !UserStore.user && !NewOrderStore.returnRouteMode) {
                values.customerData.phone = values.customerData.phone && values.customerData.phone.length > 9 && values.customerData.phone.substr(0, 1) !== "+" ? `+${values.customerData.phone}` : `${values.customerData.phone}`;
                values.customerData.phone = values.customerData.phone && values.customerData.phone.length > 9 ? `${values.customerData.phone}` : null;
                const phone = values.customerData.phone && values.customerData.phone.length > 9 ? `${values.customerData.phone}` : null;
                try {
                    await UserStore.userCreateValidation(phone, values.customerData.email);
                    await this.handleRouteErrorModal(err, values);
                } catch (e) {
                    NotificationStore.processException(e, "contact");
                } finally {
                    NewOrderStore.isLoading = false;
                }
            } else {
                await this.handleRouteErrorModal(err, values);
            }
        });
    }
    
    


    async handleRouteErrorModal(err, values) {
       
        if (NewOrderStore.mode === "hour-rental" && NewOrderStore.start !== null && NewOrderStore.finish !== null) {
            if (CarCategoryStore.isStartPrague !== true || CarCategoryStore.isFinishPrague !== true) {
                NewOrderStore.isNotice1 = true;
                if (!NewOrderStore.checkValidation) {
                    NewOrderStore.isModalOpened = true;
                } else {
                    NewOrderStore.checkValidation = false;
                }
                return true;
            }
        }

        if (!err && !NewOrderStore.editId) {
            if (NewOrderStore.returnRouteMode === false && NewOrderStore.checkDuplicateError) {
                NewOrderStore.isTransferFirstPageDuplicate(values).then((isDuplicate) => {
                    if (isDuplicate) {
                        NewOrderStore.setIsDuplicateModalOpened(true);
                    } else {
                        this.checkStartAndFinish(err, values);
                    }
                });
            } else if (NewOrderStore.returnRouteMode === true && NewOrderStore.checkDuplicateError) {
                const isDuplicate = await NewOrderStore.isTransferSecondPageDuplicate(values);
                if (isDuplicate) {
                    NewOrderStore.setIsDuplicateModalOpened(true);
                } else {
                    this.checkStartAndFinish(err, values);
                }
            } else {
                this.checkStartAndFinish(err, values);
            }
        } else {
            this.checkStartAndFinish(err, values);
        }
        return true;
    }

    checkStartAndFinish(err, values) {
        const regex = /\d+/g;
        let matches1 = 0;
        let matches2 = 0;

        if (NewOrderStore.start !== null || NewOrderStore.finish !== null) {
            let startcheck = "";
            let finshcheck = "";
            if (NewOrderStore.start !== null) {
                startcheck = NewOrderStore.start.split(",");
                const cd = NewOrderStore.start;
                matches1 = (cd.match(regex)) ? cd.match(regex) : [];
            }

            if (NewOrderStore.finish !== null) {
                finshcheck = NewOrderStore.finish.split(",");
                const xv = NewOrderStore.finish;
                matches2 = (xv.match(regex)) ? xv.match(regex) : [];
            }


            if (startcheck.length <= 2 || finshcheck.length <= 2) {
                if (!NewOrderStore.checkValidation && matches1.length < 2) {
                    NewOrderStore.isModalOpened = true;
                    NewOrderStore.isstartRed = true;
                } else if (!NewOrderStore.checkValidation && matches2.length < 2) {
                    NewOrderStore.isModalOpened = true;
                    NewOrderStore.isFinishRed = true;
                } else {
                    this.validateFieldsAction(err, values);
                    NewOrderStore.checkValidation = false;
                }
            } else {
                this.validateFieldsAction(err, values);
            }
        } else {
            this.validateFieldsAction(err, values);
        }
    }

    validateFieldsAction(err, values) {
        NewOrderStore.showRegistrationInfo = false;
        const { mode } = this;
        let badReturnDate = false;
        let badReturnDateInterval = false;
        const { priceCarsEnd, priceCarsStart, totalPrice } = getTotalPrice();
    
        const usePickupDateLiveCondition = !!(NewOrderStore.usePickupDateLive && CarCategoryStore.zoneInfo === ZoneStore.TYPE_AIRPORT_FROM);
        const trainLabelCondition = !!(CarCategoryStore.zoneInfo === ZoneStore.TYPE_TRAIN_FROM);
        const busLabelCondition = !!(CarCategoryStore.zoneInfo === ZoneStore.TYPE_BUS_FROM);
    
        if (!values.routes[0].pickupTime) {
            values.routes[0].pickupTime = moment().set("hours", 0).set("minutes", 0).set("seconds", 0);
        }
    
        if (values.routes[1] && !values.routes[1].pickupTime && NewOrderStore.backRoute) {
            values.routes[1].pickupTime = moment().set("hours", 0).set("minutes", 0).set("seconds", 0);
        }
    
        values.routes[0].usePickupDateLive = NewOrderStore.usePickupDateLive;
        if (values.routes[1]) {
            values.routes[1].usePickupDateLive = NewOrderStore.usePickupDateLive;
        }
    
        if (!NewOrderStore.returnRouteMode
            && !NewOrderStore.backRouteStep
            && NewOrderStore.backRoute
            && !NewOrderStore.editId
            && !(NewOrderStore.mode === "excursion" || NewOrderStore.mode === "hour-rental")
            && (values.routes[0].pickupDate && values.routes[1].pickupDate)) {
            const firstRoute = values.routes[0];
            const secondRoute = values.routes[1];
    
            const firstDate = firstRoute
                .pickupDate
                .set({ h: firstRoute.pickupTime.get("hour"), m: firstRoute.pickupTime.get("minute"), s: 0 });
            const secondDate = secondRoute
                .pickupDate
                .set({ h: secondRoute.pickupTime.get("hour"), m: secondRoute.pickupTime.get("minute"), s: 0 });
            if (firstDate.isAfter(secondDate, "minute")) {
                badReturnDate = true;
            }
            if (firstDate.isSame(secondDate, "minute")) {
                badReturnDate = true;
            }
            if (secondDate.diff(firstDate, "days") > 30) {
                badReturnDateInterval = true;
            }
        }
    
        let totalCapacity = 0;
        let errorMessageLowCarCapacity = "";
        let errorPickupDate = "";
        NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].forEach((selectedCar) => {
            totalCapacity += selectedCar.capacity * selectedCar.carCount;
        });
    
        if (NewOrderStore.passengersCount > totalCapacity && NewOrderStore.start && NewOrderStore.finish) {
            errorMessageLowCarCapacity = `${i18n.t("Celková kapacita vámi vybraných vozidel je menší, než počet cestujících")}. `;
        }
    
        if (values.routes.length > 1 && !(NewOrderStore.mode === "excursion" || NewOrderStore.mode === "hour-rental")) {
            const { pickupTime, pickupDate } = values.routes[1];
            if (pickupTime && pickupDate && typeof pickupDate.format === 'function' && typeof pickupTime.format === 'function') {
                const pickupDateTime = moment(`${pickupDate.format('YYYY-MM-DD')} ${pickupTime.format('HH:mm')}`);
                values.routes[1].pickupDate = pickupDateTime;
            }
        }
    
        if (values.routes[0].pickupDate) {
            const { pickupTime, pickupDate } = values.routes[0];
        
            // Validate presence of both pickupTime and pickupDate
            if (!pickupTime || !pickupDate) {
                this.form.setFields({
                    "routes[0][pickupDate]": { value: null },
                    "routes[0][pickupTime]": { value: null },
                });
                errorPickupDate += `${i18n.t("Datum a čas vyzvednutí jsou povinné.")}`;
                return;
            }
        
            // Ensure pickupDate and pickupTime are valid
            //if (!moment.isMoment(pickupDate) || !moment.isMoment(pickupTime)) {
            //    console.error("Invalid pickupDate or pickupTime format");
            //    return;
            //}
        
            const pickupDateTime = moment(`${pickupDate.format('YYYY-MM-DD')} ${pickupTime.format('HH:mm')}`);
            values.routes[0].pickupDate = pickupDateTime;
        
            // Validation constants
            const validTime = moment().add(SettingsStore.settings.minTimeCreateReservation, "minutes");
            const currentDateTime = moment();
            const currentDate = moment().startOf("day");
            const nextDay = moment().add(1, "days").startOf("day");
        
            const workingHoursFrom = moment(SettingsStore.settings.dispatchWorkingHoursFrom?.substring(11, 16) || "00:00", "HH:mm");
            const workingHoursTo = moment(SettingsStore.settings.dispatchWorkingHoursTo?.substring(11, 16) || "23:59", "HH:mm");
            const workingHoursFromTomorrow = moment(nextDay).set({
                hour: workingHoursFrom.hour(),
                minute: workingHoursFrom.minute(),
            });
        
            // Validate pickupDateTime
            if (pickupDateTime.isBefore(validTime)) {
                // Booking is made before the valid time
                this.form.setFields({
                    "routes[0][pickupDate]": { value: null },
                    "routes[0][pickupTime]": { value: null },
                });
                errorPickupDate += `${i18n.t(
                    "Čas vyzvednutí musí být nejdříve {{date}} v {{time}}. Pro dřívější vyzvednutí nás kontaktujte telefonicky.",
                    {
                        date: validTime.format(config.webDateFormat),
                        time: validTime.format(config.webTimeFormat),
                    }
                )} `;
                return;
            }
        
            if (pickupDateTime.isSame(currentDate, "day")) {
                // Booking for today: Check working hours
                if (pickupDateTime.isBefore(workingHoursFrom) || pickupDateTime.isAfter(workingHoursTo)) {
                    this.form.setFields({
                        "routes[0][pickupTime]": { value: null },
                    });
                    errorPickupDate += `${i18n.t(
                        "Čas vyzvednutí na dnešní den musí být v pracovní době od {{fromTime}} do {{toTime}}.",
                        {
                            fromTime: workingHoursFrom.format(config.webTimeFormat),
                            toTime: workingHoursTo.format(config.webTimeFormat),
                        }
                    )} `;
                    return;
                }
            }
        
            if (pickupDateTime.isSame(nextDay, "day")) {
                // Booking for tomorrow: Check if it's too early
                if (pickupDateTime.isBefore(workingHoursFromTomorrow) && currentDateTime.isAfter(workingHoursTo)) {
                    this.form.setFields({
                        "routes[0][pickupTime]": { value: null },
                    });
                    errorPickupDate += `${i18n.t(
                        "Čas vyzvednutí na zítra musí být v pracovní době po {{fromTime}} hodině.",
                        {
                            fromTime: workingHoursFromTomorrow.format(config.webTimeFormat),
                        }
                    )} `;
                    return;
                }
            }
        }
    
        if (!err
            && NewOrderStore.car
            //&& (NewOrderStore.conditionsApproved || NewOrderStore.editOrder)
            && errorMessageLowCarCapacity === ""
            && errorPickupDate === ""
            && (mode === "excursion" && NewOrderStore.excursionPoints.every(ep => ep.place && ep.place.trim().length > 0) || (
                NewOrderStore.start && NewOrderStore.finish)) && !badReturnDate && !badReturnDateInterval) {
            this.handleWithoutError(values, mode, priceCarsEnd);
        } else {
            this.handleWithError(
                mode,
                err,
                usePickupDateLiveCondition,
                busLabelCondition,
                trainLabelCondition,
                badReturnDate,
                badReturnDateInterval,
                errorMessageLowCarCapacity,
                errorPickupDate
            );
        }
        if (!err) {
            window.scrollTo(0, 0);
        }
    }
    
    handleWithError(
        mode,
        err,
        usePickupDateLiveCondition,
        busLabelCondition,
        trainLabelCondition,
        badReturnDate,
        badReturnDateInterval,
        errorMessageLowCarCapacity,
        errorPickupDate
    ) {
        let errorMessage = `${i18n.t("Prosím vyplňte")} `;
        let errorMessageSameStartAsFinish = "";
        let errorMessageBackRouteNeedToBeLater = "";
        let errorMessageBackRouteNeedToBeinThirtyDays = "";
    
        if (mode === "excursion") {
            const isMissing = NewOrderStore.excursionPoints.some(ep => !ep.place || ep.place.trim().length === 0);
            if (isMissing) {
                errorMessage += i18n.t("informace o trase").toLowerCase();
            }
        } else if (mode === "hour-rental") {
            if (!NewOrderStore.start) {
                errorMessage += i18n.t("informace o trase").toLowerCase();
            }
        } else {
            if (!NewOrderStore.start || !NewOrderStore.finish) {
                errorMessage += i18n.t("informace o trase").toLowerCase();
            }
    
            if (NewOrderStore.start && NewOrderStore.start === NewOrderStore.finish) {
                errorMessageSameStartAsFinish = i18n.t("Trasa nesmí začínat a končit na stejném místě. Prosím vyberte různé místa");
            }
        }
    
        if (!NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Vozidlo").toLowerCase()}` : `${i18n.t("Vozidlo").toLowerCase()}`;
        }
    
        if ((err && err.routes && err.routes.length && err.routes[0] && usePickupDateLiveCondition && err.routes[0].flightNumber)
            || (err && err.routes && err.routes.length && err.routes[1] && usePickupDateLiveCondition && err.routes[1].flightNumber)) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Číslo letu").toLowerCase()}` : `${i18n.t("Číslo letu").toLowerCase()}`;
        }
    
        if ((err && err.routes && err.routes.length && err.routes[0] && busLabelCondition && err.routes[0].busNumber)
            || (err && err.routes && err.routes.length && err.routes[1] && busLabelCondition && err.routes[1].busNumber)) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Číslo autobusu").toLowerCase()}` : `${i18n.t("Číslo autobusu").toLowerCase()}`;
        }
    
        if ((err && err.routes && err.routes.length && err.routes[0] && trainLabelCondition && err.routes[0].trainNumber)
            || (err && err.routes && err.routes.length && err.routes[1] && trainLabelCondition && err.routes[1].trainNumber)) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Číslo vlaku").toLowerCase()}` : `${i18n.t("Číslo vlaku").toLowerCase()}`;
        }
    
        if (err && err.routes && err.routes.length && err.routes[0] && err.routes[0].pickupDate) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Datum").toLowerCase()}` : `${i18n.t("Datum").toLowerCase()}`;
        }
    
        if (err && err.routes && err.routes.length && err.routes[0] && err.routes[0].pickupTime) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Čas").toLowerCase()}` : `${i18n.t("Čas").toLowerCase()}`;
        }
    
        if (err && err.routes && err.routes.length && NewOrderStore.backRoute && err.routes[1] && (err.routes[1].pickupTime || err.routes[1].pickupDate)) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Čas zpáteční cesty").toLowerCase()}` : `${i18n.t("Čas zpáteční cesty").toLowerCase()}`;
        }
    
        if (err && err.customerData && err.customerData.firstName) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Jméno").toLowerCase()}` : `${i18n.t("Jméno").toLowerCase()}`;
        }
    
        if (err && err.customerData && err.customerData.lastName) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Příjmení").toLowerCase()}` : `${i18n.t("Příjmení").toLowerCase()}`;
        }
    
        if (err && err.customerData && err.customerData.email) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("E-mail").toLowerCase()}` : `${i18n.t("E-mail").toLowerCase()}`;
        }
    
        if (err && err.customerData && err.customerData.companyName) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("Název společnosti").toLowerCase()}` : `${i18n.t("Název společnosti").toLowerCase()}`;
        }
    
        //if (!NewOrderStore.conditionsApproved && !NewOrderStore.editOrder) {
        //    errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `, ${i18n.t("souhlas s podmínkami provozu").toLowerCase()}` : `${i18n.t("souhlas s podmínkami provozu").toLowerCase()}`;
        //}
    
        if (checkBabyseats(NewOrderStore) == 0) {
            errorMessage += errorMessage !== `${i18n.t("Prosím vyplňte")} ` ? `,${i18n.t("dětské sedačky by měly být nižší než počet cestujících.")}` : `${i18n.t("dětské sedačky by měly být nižší než počet cestujících.")}`;
        }
    
        errorMessage += ". ";
    
        if (badReturnDate) {
            errorMessageBackRouteNeedToBeLater += `${i18n.t("Zpáteční cesta musí být později")}. `;
        }
    
        if (badReturnDateInterval) {
            errorMessageBackRouteNeedToBeinThirtyDays += `${i18n.t("Zpáteční cesta nesmí být za více jak 30 dní. Případně objednejte jako každou cestu zvlášť.")}. `;
        }
    
        const lastComma = errorMessage.lastIndexOf(",");
        if (lastComma !== -1) {
            errorMessage = `${errorMessage.substring(0, lastComma)} ${i18n.t("a")}${errorMessage.substring(lastComma + 1)}`;
        }
    
        errorMessage = errorMessage !== `<WarningOutlined /> ${i18n.t("Prosím vyplňte")} . ` ? errorMessage : "";
    
        if (!(errorMessage === ""
            && errorMessageSameStartAsFinish === ""
            && errorMessageBackRouteNeedToBeLater === ""
            && errorMessageBackRouteNeedToBeinThirtyDays === ""
            && errorMessageLowCarCapacity === ""
            && errorPickupDate === "")) {
                NewOrderStore.error = (
                    <span>
                        <WarningOutlined /> {errorPickupDate}
                        {errorMessageLowCarCapacity}
                        {errorMessageSameStartAsFinish}
                        {errorMessageBackRouteNeedToBeLater}
                        {errorMessageBackRouteNeedToBeinThirtyDays}
                        {errorMessage}
                    </span>
                );

                setTimeout(() => {
                    NewOrderStore.error = null;
                }, 15000);

            NewOrderStore.scrollToMyRef();
        }
    }
    
    addCustomerData(values) {
        let tagName = null;
        let contactPhone = null;
    
        if (values.customerData) {
            const { customerTagName, customerContactPhone } = this.getCustomerData(values);
    
            tagName = customerTagName;
            contactPhone = customerContactPhone;
        } else {
            values.customer = UserListStore.user.id;
        }
    
        return { tagName, contactPhone };
    }
    
    handleWithoutError(values, mode, priceCarsEnd) {
        // NewOrderStore.start && (NewOrderStore.finish || mode === "hour-rental"))) && !badReturnDate) {
        NewOrderStore.error = null;
        const { tagName, contactPhone } = this.addCustomerData(values);
    
        // cesta tam
        values.routes.forEach((route, id) => {
            this.addThereRouteParameters(route, id, mode, tagName, contactPhone);
        });
    
        // cesta zpět
        if (NewOrderStore.returnRouteMode) {
            this.addReturnRouteModeRouteParameters(values, priceCarsEnd);
        } else if (NewOrderStore.backRouteStep) {
            this.addBackRouteStepParameters(values, priceCarsEnd);
        } else {
            this.addOtherParameters(values, priceCarsEnd);
        }
    
    }
    
    addThereRouteParameters(
        route,
        id,
        mode,
        tagName,
        contactPhone
    ) {
        if (id === 0) {
            if (NewOrderStore.editRouteId) {
                route.id = NewOrderStore.editRouteId;
            }
    
            if (mode === "excursion") {
                const pointsCount = NewOrderStore.excursionPoints.length;
                NewOrderStore.start = NewOrderStore.excursionPoints[0].place;
                NewOrderStore.finish = NewOrderStore.excursionPoints[pointsCount - 1].place;
                NewOrderStore.startName = NewOrderStore.excursionPoints[0].name;
                NewOrderStore.finishName = NewOrderStore.excursionPoints[pointsCount - 1].name;
            }
    
            if (mode === "excursion" || mode === "order") {
                route.distance = CarCategoryStore.routeDistance;
                route.duration = CarCategoryStore.routeDuration * 60;
            }
    
            route.start = NewOrderStore.start;
            route.finish = NewOrderStore.finish;
            route.startName = NewOrderStore.startName;
            route.finishName = NewOrderStore.finishName;
    
            route.contactPersonTagName = tagName;
            route.contactPersonPhone = contactPhone;
    
            route.withoutLuggage = NewOrderStore.defaultValues.withoutLuggage;
            route.withLuggage = NewOrderStore.defaultValues.withLuggage;
            route.excessiveLuggage = NewOrderStore.defaultValues.excessiveLuggage;
    
            route.paymentType = NewOrderStore.paymentType;
    
            route.kids1 = NewOrderStore.kids1[NewOrderStore.getRouteWay()];
            route.kids2 = NewOrderStore.kids2[NewOrderStore.getRouteWay()];
            route.kids3 = NewOrderStore.kids3[NewOrderStore.getRouteWay()];
    
            if (!route.pickupDate) {
                route.pickupDate = NewOrderStore.formValues.routes[0].pickupDate;
            }
    
            route.pickupDate = route
                .pickupDate
                .set({ h: route.pickupTime.get("hour"), m: route.pickupTime.get("minute"), s: 0 });
            delete route.pickupTime;
    
            const { selectedCars } = NewOrderStore;
            route.orderRouteCars = [];
    
            if (!selectedCars.backRoute.length && NewOrderStore.backRoute) {
                NewOrderStore.selectedCars.backRoute = selectedCars.thereRoute.map(cc => toJS(cc));
    
                // NewOrderStore.selectedExtras.backRoute = NewOrderStore.selectedExtras.thereRoute; // FIXME: ISSUE 417
                NewOrderStore.chooseCustomCar.backRoute = NewOrderStore.chooseCustomCar.thereRoute;
    
                NewOrderStore.kids1.backRoute = NewOrderStore.kids1.thereRoute;
                NewOrderStore.kids2.backRoute = NewOrderStore.kids2.thereRoute;
                NewOrderStore.kids3.backRoute = NewOrderStore.kids3.thereRoute;
                NewOrderStore.chooseChild.backRoute = false;
            }
    
            NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].forEach((category) => {
                if (category.combination) {
                    this.calculateCombinationPrice(category, route);
                } else {
                    this.calculateThereRoutePrice(category, route);
                }
            });
    
            route.status = OrdersListStore.STATUS_NOT_CONFIRMED;
            route.paymentCurrency = NewOrderStore.paymentCurrency;
            if (NewOrderStore.mode === "excursion") {
                route.excursionPoints = NewOrderStore.excursionPoints;
            }
            if (NewOrderStore.mode !== "hour-rental") {
                route.rentHours = null;
            } else {
                route.rentHours = NewOrderStore.rentHours;
            }
    
            route.extras = NewOrderStore.selectedExtras[NewOrderStore.getRouteWay()].map((extra) => {
                const routeExtra = { ...extra };
                if (NewOrderStore.isForPricing) {
                    routeExtra.price = 0;
                    routeExtra.totalPrice = 0;
                }
                return routeExtra;
            });
    
            NewOrderStore.extraAmounts = {};
    
            route.passengersCount = NewOrderStore.passengersCount;
        }
    }
    
    addReturnRouteModeRouteParameters(values, priceCarsEnd) {
        NewOrderStore.formValues.routes[1] = values.routes[0]; // eslint-disable-line prefer-destructuring
        NewOrderStore.formValues.routes[1].pickupDate = values.routes[1].pickupDate; // eslint-disable-line prefer-destructuring
        NewOrderStore.formValues.routes[1].pickupTime = values.routes[1].pickupTime; // eslint-disable-line prefer-destructuring
        NewOrderStore.formValues.routes[1].trainNumber = values.routes[1].trainNumber; // eslint-disable-line prefer-destructuring
        NewOrderStore.formValues.routes[1].busNumber = values.routes[1].busNumber; // eslint-disable-line prefer-destructuring
        NewOrderStore.formValues.routes[1].flightNumber = values.routes[1].flightNumber; // eslint-disable-line prefer-destructuring
        NewOrderStore.formValues.routes[1].usePickupDateLive = values.routes[1].usePickupDateLive; // eslint-disable-line prefer-destructuring
    
        this.calculateReturnRoutePrice(values.routes[1], priceCarsEnd);
    
        if (!NewOrderStore.backRouteStep) { // FIXME: ISSUE 417
            NewOrderStore.formValues.routes[1].extras = [];
        }
        NewOrderStore.step = 2;
    }
    
    addBackRouteStepParameters(values, priceCarsEnd) {
        NewOrderStore.formValues = values;
        NewOrderStore.backRouteStepBackButton = true;
    
        const { start, finish } = NewOrderStore;
    
        NewOrderStore.startExtras = ExtraStore.extras.filter(extra => extra.amount);
        NewOrderStore.startPrice = NewOrderStore.price;
        NewOrderStore.startPriceCars = NewOrderStore.priceCars;
    
        NewOrderStore.startValue = finish;
        NewOrderStore.finishValue = start;
    
        const { startName } = NewOrderStore;
    
        NewOrderStore.startName = NewOrderStore.finishName;
        NewOrderStore.finishName = startName;
        NewOrderStore.start = finish;
        NewOrderStore.finish = start;
    
        this.calculateReturnRoutePrice(values.routes[1], priceCarsEnd);
    
        NewOrderStore.returnRouteMode = true;
        NewOrderStore.returnRouteModeInit = true;
    }
    
    addOtherParameters(values, priceCarsEnd) {
        NewOrderStore.formValues = values;
    
        if (NewOrderStore.backRoute
            && !NewOrderStore.editId
            && !(NewOrderStore.mode === "excursion" || NewOrderStore.mode === "hour-rental")
        ) {
            const route = values.routes[0];
            const returnRoute = values.routes[1];
    
            const { start } = route;
            route.start = route.finish;
            route.finish = start;
    
            const { startName } = NewOrderStore;
            NewOrderStore.startName = NewOrderStore.finishName;
            NewOrderStore.finishName = startName;
            route.startName = NewOrderStore.startName;
            route.finishName = NewOrderStore.finishName;
    
            if (moment.isMoment(returnRoute.pickupDate)) {
                route.pickupDate = moment(returnRoute.pickupDate);
            }
            route.pickupDate = route
                .pickupDate
                .set({ h: returnRoute.pickupTime.get("hour"), m: returnRoute.pickupTime.get("minute"), s: 0 });
            delete route.pickupTime;
    
            NewOrderStore.formValues.routes[1] = route;
            if (!NewOrderStore.backRouteStep) { // FIXME: ISSUE 417
                NewOrderStore.formValues.routes[1].extras = [];
            }
    
            NewOrderStore.formValues.routes[1].trainNumber = values.routes[1].trainNumber; // eslint-disable-line prefer-destructuring
            NewOrderStore.formValues.routes[1].busNumber = values.routes[1].busNumber; // eslint-disable-line prefer-destructuring
            NewOrderStore.formValues.routes[1].flightNumber = values.routes[1].flightNumber; // eslint-disable-line prefer-destructuring
            NewOrderStore.formValues.routes[1].usePickupDateLive = values.routes[1].usePickupDateLive; // eslint-disable-line prefer-destructuring
    
            this.calculateReturnRoutePrice(values.routes[1], priceCarsEnd);
        }
    
        // remove second route if no back route!
        if (!NewOrderStore.backRoute) {
            NewOrderStore.formValues.routes = [NewOrderStore.formValues.routes[0]];
        }
        NewOrderStore.step = 2;
    }
    
    calculateReturnRoutePrice(route, priceCarsEnd) {
        const newReturnPrice = [];
    
        NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].forEach((category) => {
            if (category.combination) {
                this.calculateCombinationPrice(category, route);
            } else {
                newReturnPrice.push(
                    this.addReturnRoutePrice(category, priceCarsEnd)
                );
            NewOrderStore.formValues.routes[1].orderRouteCars = newReturnPrice;
            }
        });
    }
    
    calculateThereRoutePrice(category, route) {
        let newPrice;
        if (checkDiscountFrom(category) && checkSurchargesFrom(category)) {
            const getSurcharge = parseFloat(category.thereSurcharges) - parseFloat(category.price);
            newPrice = category.thereDiscount + getSurcharge;
        } else if (checkDiscountFrom(category)) {
            newPrice = category.thereDiscount;
        } else if (checkSurchargesFrom(category)) {
            newPrice = category.thereSurcharges;
        } else {
            newPrice = NewOrderStore.isForPricing ? 0 : category.price;
        }
    
        route.orderRouteCars.push({
            carCategory: category,
            carCount: category.carCount,
            priceClient: Math.round(newPrice),
        });
    }
    
    addReturnRoutePrice(category, priceCarsEnd) {
        let newPrice;
    
        if (checkDiscountTo(category) && checkSurchargesTo(category)) {
            const getSurchargeTo = parseFloat(category.backSurcharges) - parseFloat(category.price);
            newPrice = category.backDiscount + getSurchargeTo;
        } else if (checkDiscountTo(category)) {
            newPrice = category.backDiscount;
        } else if (checkSurchargesTo(category) && NewOrderStore.car && NewOrderStore.car.isreturnDiscount) {
            const surchargesBack = category.backSurcharges;
            const getExtraDiscount = (priceCarsEnd * parseFloat(NewOrderStore.car.discountPercentage)) / 100;
            const extraDiscountedPrice = surchargesBack - getExtraDiscount;
            newPrice = extraDiscountedPrice;
        } else if (checkSurchargesTo(category)) {
            newPrice = category.backSurcharges;
        }else if (
            !checkDiscountTo(category) &&
            NewOrderStore.car &&
            NewOrderStore.car.isreturnDiscount &&
            !UserListStore.user.isPartner
        ) {
        
            const getExtraDiscount = (category.price * parseFloat(category.discountPercentage)) / 100;
            const extraDiscountedPrice = category.price - getExtraDiscount;
    
        
            newPrice = extraDiscountedPrice;
        
        } else {
            newPrice = NewOrderStore.isForPricing ? 0 : category.price;
        }
    
        return {
            carCategory: category,
            carCount: category.carCount,
            priceClient: Math.round(newPrice),
        };
    }
    
    calculateCombinationPrice(category, route) {
        if (!route.orderRouteCars) {
            route.orderRouteCars = [];
        }
        category.combination.forEach((combination) => {
            const priceClient = combination.price || 0;
    
            route.orderRouteCars.push({
                carCategory: combination,
                carCount: combination.carCount,
                priceClient: priceClient,
            });
        });
    }
    
    getCustomerData(values) {
        let tagName = null;
        let contactPhone = null;
    
        if (values.customerData.tagName) {
            tagName = values.customerData.tagName;
            NewOrderStore.contactTagName = tagName;
        }
    
        if (values.customerData.phone && values.customerData.phone.length > 9) {
            contactPhone = values.customerData.phone.substr(0, 1) !== "+" ? `+${values.customerData.phone}` : `${values.customerData.phone}`;
            NewOrderStore.contactPhone = contactPhone;
        } else {
            values.customerData.phone = null;
            NewOrderStore.contactPhone = null;
        }
        NewOrderStore.prefixLength = prefixLength;
    
        values.customerData.greeting = parseInt(values.customerData.greeting, 10);
        values.customerData.createNewEntity = UserStore.isEmailExists && !UserStore.isHashVerified;
    
        return { customerTagName: tagName, customerContactPhone: contactPhone };
    }
    }
    /* eslint-enable no-param-reassign */
    
    export default NewOrderFormSchema;
    