import React, { Component } from "react";
import { observer } from "mobx-react";
import {
    Modal, Spin, Button, Row, Col,
} from "antd";
import PropTypes from "prop-types";

import { Form } from "@ant-design/compatible";
import i18n from "../../../../core/i18n";
import InvalidAddressModalStore from "../../../../stores/Order/InvalidAddressModalStore";
import InvalidAddressFormSchema from "../../../../forms/Order/InvalidAddressFormSchema";
import NewOrderStore from "../../../../stores/Order/NewOrderStore";
import Notification from "../../../Notification";

class InvalidAddressModal extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    constructor(props) {
        super(props);
        const { form } = props;
        this.form = new InvalidAddressFormSchema(form);
    }

    render() {
        const { form } = this.props;
        this.form = new InvalidAddressFormSchema(form);

        return (
            <Modal
                width={750}
                title={i18n.t("Nenašli jste svou adresu?")}
                footer={false}
                onCancel={() => { InvalidAddressModalStore.close(); }}
                className="modal-invalid-address"
                open={InvalidAddressModalStore.isModalOpened}
            >
                <Spin spinning={InvalidAddressModalStore.isLoading}>
                    <Notification type="invalidAddress" />
                    {!InvalidAddressModalStore.alreadySent && InvalidAddressModalStore.isModalOpened && (
                        <React.Fragment>
                            <div style={{ marginBottom: 20 }}>{i18n.t("Kontaktujte nás. Vytvoříme pro vás rezervaci dle těchto požadavků.")}</div>
                            <Form onSubmit={(e) => { e.preventDefault(); this.form.onSubmit(); }} layout="vertical">

                                {this.form.render("invalidAddressStart")}

                                {this.form.render("invalidAddressFinish")}

                                <Form.Item>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            {this.form.render("firstName")}
                                        </Col>
                                        <Col span={12}>
                                            {this.form.render("lastName")}
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            {this.form.render("email")}
                                        </Col>
                                        <Col span={12}>
                                            {this.form.render("phone")}
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item>
                                    <Row gutter={20}>
                                        <Col span={16}>
                                            {this.form.render("note")}
                                        </Col>
                                        <Col span={8}>
                                            {this.form.render("passengersCount", {
                                                initialValue: NewOrderStore.passengersCount,
                                            })}
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item>
                                    {this.form.render("recaptcha")}
                                </Form.Item>

                                <div className="text-align--right">
                                    <Button size="large" type="primary" htmlType="submit">
                                        {i18n.t("Odeslat")}
                                    </Button>
                                </div>
                            </Form>
                        </React.Fragment>
                    )}

                </Spin>
            </Modal>
        );
    }
}

export default Form.create()(observer(InvalidAddressModal));
