
import React, { Component } from "react";
import { Empty } from "antd";
import { observer } from "mobx-react";
import i18n from "../../../../core/i18n";
import CarCategoryStore from "../../../../stores/CarCategory/CarCategoryStore";
import CustomCarSelect from "./CustomCars/CustomCarSelect";

class CustomCarSelectList extends Component {
    render() {
        const { customCarCategories } = CarCategoryStore;
        let CustomCarSelectListReturn = customCarCategories.map((car, index) => <CustomCarSelect key={car.id} car={car} index={index} />);

        if (!CustomCarSelectListReturn && !CarCategoryStore.isLoading) {
            CustomCarSelectListReturn = (() => (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={i18n.t("Žádné vozidla pro daný výběr")}
                />
            ));
        }

        return CustomCarSelectListReturn;
    }
}

export default observer(CustomCarSelectList);
