import React from "react";
import { Carousel, Avatar } from "antd";
import { CarOutlined } from "@ant-design/icons";
import FileUploadStore from "../../stores/FileUploadStore";

const GetCarImg = ({ item }) => {
    let img;
    if (item.combination && item.combination.length > 1) {
        img = (
            <Carousel autoplay>
                {item.combination.map((car, index) => (
                    <div key={index}>
                        <Avatar src={FileUploadStore.getImgBaseUrl(car.imgId)}  className="custom-avatar" />
                    </div>
                ))}
            </Carousel>
        );
    } else if (item && item.combination && item.combination.length === 1) {
        const getItem = item.combination[0];
        
        img = <Avatar src={FileUploadStore.getImgBaseUrl(getItem.imgId)} shape="square"  className="custom-avatar" icon={<CarOutlined />} />;
    } else {
        img = <Avatar src={FileUploadStore.getImgBaseUrl(item.imgId)} shape="square"  className="custom-avatar" icon={<CarOutlined />} />;
    }

    return img;
};

export default GetCarImg;
