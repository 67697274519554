import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import NewOrderStore from "../../../../stores/Order/NewOrderStore";
import PriceAndRecap from "../orderStep1Page/form/PriceAndRecap/PriceAndRecap";
import PriceAndRecapDiscount from "../orderStep1Page/form/PriceAndRecap/PriceAndRecapDiscount";

class OrderFormCard extends Component {
    static propTypes = {
        values: PropTypes.objectOf(PropTypes.any).isRequired,
    }

    render() {

            const isDiscount = () => {
                if (!NewOrderStore.returnRouteMode && NewOrderStore.car?.thereDiscount) {
                    return true;
                }
    
                if (NewOrderStore.returnRouteMode && NewOrderStore.car?.backDiscount) {
                    return true;
                }
    
                return false;
            };
    
            const isSurcharges = () => {
                if (!NewOrderStore.returnRouteMode && NewOrderStore.car?.thereSurcharges) {
                    return true;
                }
    
                if (NewOrderStore.returnRouteMode && NewOrderStore.car?.backSurcharges) {
                    return true;
                }
    
                return false;
            };

        return (
            <div className="order-form__card">
                {NewOrderStore.car && isDiscount || NewOrderStore.car && isSurcharges() || (NewOrderStore.car && NewOrderStore.car.isreturnDiscount && UserListStore.user && !UserListStore.user.isPartner)
                        ? <PriceAndRecapDiscount /> : NewOrderStore.car ? <PriceAndRecap /> : null }
            </div>
        );
    }
}

export default observer(OrderFormCard);
