import React from "react";
import { observer } from "mobx-react";
import AutoCompleteLocation from "../../../../../autoCompleteLocation/AutoCompleteLocation";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";
import i18n from "../../../../../../core/i18n";
import { Spin } from "antd";

const onSearchHandle = (phrase) => {
    NewOrderStore.finish = null;
    NewOrderStore.isFinishRed = false;
    NewOrderStore.finishValue = phrase;
    CarCategoryStore.routeDistance = null;
    //NewOrderStore.calculatePrice();
};

const onSelectHandle = (value, routeNumber) => {
    const originalName = NewOrderStore.getOriginTranslation(value, "cs"); // Translate back to Czech
    //console.log("Finish - Selected Translated Value:", value);
    //console.log("Finish - Mapped Original Czech Name:", originalName);

    NewOrderStore.finishValue = value;
    NewOrderStore.onSelectRoutePoint(originalName, routeNumber, "finish"); // Use original Czech name
    NewOrderStore.isFinishRed = false;
};

const AutoCompleteLocationStoreFinish = ({ placeHolder, routeNumber, ismapModal = true }) => {
    if (NewOrderStore.finishValue === null) {
        return <Spin spinning />;
    }

    const disabled = NewOrderStore.backRouteStep && NewOrderStore.returnRouteMode;

    return (
        <AutoCompleteLocation
            id="placeTo"
            isStart={false}
            onSearch={onSearchHandle}
            onSelect={(value) => { onSelectHandle(value, routeNumber); }}
            style={{ width: "100%" }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 300 }}
            placeholder={i18n.t(placeHolder)}
            defaultValue={NewOrderStore.finishValue || ""}
            value={NewOrderStore.finishValue || ""}
            ismapModal={ismapModal}
            disabled={disabled}
        />
    );
};

export default observer(AutoCompleteLocationStoreFinish);

