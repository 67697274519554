import moment from 'moment-timezone';
import NewOrderStore from "../../stores/Order/NewOrderStore";

export const checkDiscountFrom = (dataCollection) => {
    if (!dataCollection) return false;

    const getDates = NewOrderStore.getFormattedPickupDates();
    const pickupDate = moment(getDates.pickupDateTime);
    const discountMinDate = moment(dataCollection.thereDiscountMinDate);
    const discountMaxDate = moment(dataCollection.thereDiscountMaxDate);

    return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
};

export const checkDiscountTo = (dataCollection) => {
    if (!dataCollection) return false;

    const getDates = NewOrderStore.getFormattedPickupDates();
    const pickupDate = moment(getDates.returnPickupDateTime);
    const discountMinDate = moment(dataCollection.backDiscountMinDate);
    const discountMaxDate = moment(dataCollection.backDiscountMaxDate);

    return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
};

export const checkSurchargesFrom = (dataCollection) => {
    if (!dataCollection) return false;

    const getDates = NewOrderStore.getFormattedPickupDates();
    const pickupDate = moment(getDates.pickupDateTime);
    const surchargesMinDate = moment(dataCollection.thereSurchargesMinDate);
    const surchargesMaxDate = moment(dataCollection.thereSurchargesMaxDate);

    return pickupDate.isBetween(surchargesMinDate, surchargesMaxDate, null, '[]');
};

export const checkSurchargesTo = (dataCollection) => {
    if (!dataCollection) return false;

    const getDates = NewOrderStore.getFormattedPickupDates();
    const pickupDate = moment(getDates.returnPickupDateTime);
    const surchargesMinDate = moment(dataCollection.backSurchargesMinDate);
    const surchargesMaxDate = moment(dataCollection.backSurchargesMaxDate);

    return pickupDate.isBetween(surchargesMinDate, surchargesMaxDate, null, '[]');
};

