import React from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import i18n from "../../../../../core/i18n";
import UserListStore from "../../../../../stores/User/UserListStore";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import UserStore from "../../../../../stores/UserStore";

const TravelerSummary = ({ values, formItemLayout }) => {
    const FormItem = Form.Item;
    const customer = values.customerData.email ? values.customerData : UserListStore.user;
    const tagName = values.customerData.tagName ? values.customerData.tagName : values.routes[0].contactPersonTagName;
    NewOrderStore.formValues.customerData = customer;

    let user = {};

    if (values.customerData.phone) {
        user = values.customerData;
    } else if (UserListStore.user.phone) {
        user = UserListStore.user;
    }

    let phone = null;

    if (user && user.phone) {
        phone = UserStore.getPhoneNum(user.phone);
    }

    if (NewOrderStore.contactPhone !== null) {
        phone = UserStore.getPhoneNum(NewOrderStore.contactPhone);
    }

    return (
        <>
            {tagName && (
                <FormItem
                    {...formItemLayout}
                    label={i18n.t("Cestující (jméno na cedulce)")}
                    className="non-bold-label"
                >
                    <div className="tagName highlighted-value">
                        {tagName}
                    </div>
                </FormItem>
            )}
            {phone && customer.id && (
                <FormItem
                    {...formItemLayout}
                    label={i18n.t("Mobilní telefon")}
                    className="non-bold-label"
                    
                >
                    <div className="phoneRow">
                        <span className="highlighted-value">
                            {phone}
                        </span>
                    </div>
                </FormItem>
            )}
        </>
    );
};

TravelerSummary.propTypes = {
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TravelerSummary;
