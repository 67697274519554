import React from 'react';
import { useGeolocated } from 'react-geolocated';
import getConfig from 'next/config';
import NewOrderStore from '../../stores/Order/NewOrderStore';
import AutoCompleteLocationStore from '../../stores/AutoCompleteLocation/AutoCompleteLocationStore';
import i18n from '../../core/i18n';

const { publicRuntimeConfig } = getConfig();

const CurrentLocation = () => {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    const fetchLocationData = async () => {
        if (!coords) {
            if (AutoCompleteLocationStore.startcurrentLocation) {
                NewOrderStore.start = '';
                NewOrderStore.startValue = '';
            } else if (AutoCompleteLocationStore.finishcurrentLocation) {
                NewOrderStore.finish = '';
                NewOrderStore.finishValue = '';
            }
            return; 
        }

        try {
            const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.longitude},${coords.latitude}.json?access_token=${publicRuntimeConfig.mapboxServerApiKey}`);
            const data = await response.json();
            if (data.features && data.features[0]) {
                const address = data.features[0].place_name;
                if (AutoCompleteLocationStore.startcurrentLocation) {
                    NewOrderStore.start = address;
                    NewOrderStore.startValue = address;
                } else if (AutoCompleteLocationStore.finishcurrentLocation) {
                    NewOrderStore.finish = address;
                    NewOrderStore.finishValue = address;
                }
            }
        } catch (error) {
            console.log('Error fetching location data', error);
        } finally {
            AutoCompleteLocationStore.startcurrentLocation = false;
            AutoCompleteLocationStore.finishcurrentLocation = false;
        }
    };


    React.useEffect(() => {
        fetchLocationData();
    }, [coords]); 

    return !isGeolocationAvailable ? (
        <div>{i18n.t('Your browser does not support Geolocation')}</div>
    ) : !isGeolocationEnabled ? (
        <div>{i18n.t('Geolocation is not enabled')}</div>
    ) : (
        <div>{i18n.t('Getting the location data...')}</div>
    );
};

export default CurrentLocation;
