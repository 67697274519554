import React, { Component } from "react";
import {
    Row, Col, Spin, Popover, Alert,
} from "antd";
import {
    InfoCircleOutlined, QuestionCircleOutlined
} from "@ant-design/icons";
import { observer } from "mobx-react";
import getRouteDuration from "../../../../../utils/newOrderStore/getRouteDuration";
import i18n from "../../../../../core/i18n";
import CarCategoryStore from "../../../../../stores/CarCategory/CarCategoryStore";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";

class RenderStats extends Component {
    render() {
        const routeDuration = getRouteDuration(CarCategoryStore.routeDuration);
        let routeTotalDuration = null;
        if (CarCategoryStore.routeTotalDuration) {
            routeTotalDuration = getRouteDuration(CarCategoryStore.routeTotalDuration);
        }
        
       let origin="";
       let destination="";
       let isdisplyatag=false;
       let waypoints='';
       if (NewOrderStore.mode !== "hour-rental" && NewOrderStore.mode !== "excursion") {

         origin=NewOrderStore.startValue;
         destination=NewOrderStore.finishValue;
         isdisplyatag=true;
       }
       if (NewOrderStore.mode == "excursion") {
            if (NewOrderStore.excursionPoints[NewOrderStore.excursionPoints.length-1]) {
                origin=NewOrderStore.excursionPoints[NewOrderStore.excursionPoints.length-1].place;
                destination=NewOrderStore.excursionPoints[0].place;
                NewOrderStore.excursionPoints.forEach(element => {
                   
                    if (element.place && element.place!=origin && element.place!=destination) {
                        waypoints+=''+element.place+'|'; 
                    }
                    
                });
              
                isdisplyatag=true;
            }
            
       }

     

        return (
            <>
                {CarCategoryStore.routeDistance && routeDuration && (NewOrderStore.finishValue || NewOrderStore.mode === "excursion") && !CarCategoryStore.isLoading ? (
                    <React.Fragment>
                        {(CarCategoryStore.routeDistance !== -1 && CarCategoryStore.routeDuration !== -1) ? (
                            <Spin size="small" spinning={CarCategoryStore.isLoading}>
                                <Row gutter={20} style={{ marginTop: 18 }}>
                                    <Col xs={24} sm={24}>
                                        <Row gutter={24} type="flex">
                                            {isdisplyatag && <Col xs={24} lg={9} xl={9}>
                                                <div className="travel-time rtl" style={{ fontSize: 12 }}>
                                                    <span >
                                                        <InfoCircleOutlined style={{ marginRight: 4, color: "#025ba7" }}/>
                                                        <a style={{ color: "#000"}} href={`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}&travelmode=driving&waypoints=${encodeURIComponent(waypoints)}`} target="_blank">{i18n.t("Show route on Google map")}</a>
                                                    </span>
                                                </div>
                                            </Col>}   
                                            <Col xs={24} lg={7} xl={7}>
                                                <div className="distance rtl" style={{ fontSize: 12 }}>
                                                    <span>
                                                        {i18n.t("Vzdálenost")}
                                                        {":"}
                                                    </span>
                                                    &nbsp;
                                                    <span>
                                                    <strong style={{ color: "#025ba7" }}>{`${CarCategoryStore.routeDistance} km`}</strong>
                                                        <Popover
                                                            overlayClassName="order-form__popover"
                                                            placement="top"
                                                            content={i18n.t("Jedná se o odhad vzdálenosti")}
                                                        >
                                                            <QuestionCircleOutlined style={{ marginLeft: 4, color: "#025ba7" }}/>
                                                        </Popover>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col xs={24} lg={8} xl={8}>
                                                <div className="travel-time rtl" style={{ fontSize: 12 }}>
                                                    <span>
                                                        {routeTotalDuration && NewOrderStore.mode === "excursion"
                                                            ? i18n.t("Celková doba exkurze")
                                                            : i18n.t("Doba jízdy")}
                                                        {":"}
                                                    </span>
                                                    &nbsp;
                                                    <span>
                                                        <strong style={{ color: "#025ba7" }}>
                                                            {routeTotalDuration && NewOrderStore.mode === "excursion" ? routeTotalDuration : routeDuration}
                                                        </strong>
                                                        <Popover
                                                            overlayClassName="order-form__popover"
                                                            placement="top"
                                                            content={routeTotalDuration && NewOrderStore.mode === "excursion"
                                                                ? i18n.t("Jedná se o odhad času jízdy včetně zvolených hodin doby pobytu v daném místě.")
                                                                : i18n.t("Jedná se o odhad času jízdy")}
                                                        >
                                                            <QuestionCircleOutlined style={{ marginLeft: 4, color: "#025ba7" }}/>
                                                        </Popover>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Spin>
                        ) : (
                            
                            <Row type="flex" align="middle" style={{ marginTop: 10,display:'none' }}>
                                <Col xs={24} sm={{ span: 19, offset: 5 }}>
                                    <Alert
                                        message={i18n.t("Nepodařilo se určit vzdálenost nebo je vzdálenost příliš velká.")}
                                        banner
                                        type="error"
                                    />
                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                )
                    : (
                        (NewOrderStore.start!=null && NewOrderStore.finish!=null) && (
                            <Row type="flex" align="middle" style={{ marginTop: 10,display:(NewOrderStore.start!=null && NewOrderStore.finish!=null && NewOrderStore.start.toLowerCase()==NewOrderStore.finish.toLowerCase())?'block':'none' }}>
                            <Col xs={24} sm={{ span: 19, offset: 5 }}>
                                
                                <Alert
                                    message={i18n.t("Pickup and destination must be different")}
                                    banner
                                    type="error"
                                />
                            </Col>
                        </Row>
                        )
                      
                    )
                }

            </>
        );
    }
}

export default observer(RenderStats);
